import { action, makeObservable, observable } from 'mobx'

import type AppStore from '@src/app/AppStore'

export interface ToastAction {
  type: 'primary' | 'secondary' | 'destructive'
  /**
   * The title can either be a ReactNode or `Undo`.
   *
   * If the title is `Undo`, pressing the `z` key while the tooltip is open
   * will call the `onClick` callback.
   */
  title: 'Undo' | React.ReactNode
  onClick?: () => void
}

export interface OpenToastState {
  open: true
  type: 'info' | 'error' | 'loading' | 'special'
  message: React.ReactNode
  duration?: number
  actions?: ToastAction[]
}

export interface ClosedToastState {
  open: false
}

export type ToastState = OpenToastState | ClosedToastState

class ToastUiStore {
  state: ToastState = { open: false }

  constructor(private app: AppStore) {
    makeObservable(this, {
      state: observable.ref,
      show: action,
      showSpecial: action,
      showLoading: action,
      showError: action,
      hide: action,
    })
  }

  show = (state: Omit<OpenToastState, 'open' | 'type'>): void => {
    this.state = { open: true, type: 'info', ...state }
  }

  showSpecial = (content: React.ReactNode, duration = 60000): void => {
    this.state = { open: true, type: 'special', message: content, duration }
  }

  showLoading = (message: string, actions?: ToastAction[]): void => {
    this.state = { open: true, type: 'loading', message, actions }
  }

  showError = (
    error: unknown,
    options?: Pick<OpenToastState, 'actions' | 'duration'>,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.sound.play('error')
    this.state = {
      open: true,
      type: 'error',
      message: error instanceof Error ? error.message : String(error),
      actions: options?.actions,
      duration: options?.duration,
    }
  }

  hide = (): void => {
    this.state = { open: false }
  }
}

export default ToastUiStore
