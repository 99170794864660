import type { TagModel } from '@src/service/model/tags'

import type Heap from './Heap'

export default class AiCallTagsAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  callTagClickedOnChatsView(tag: TagModel) {
    this.heap.track('call_tag_clicked_chats_view', { tagName: tag.name })
  }

  callTagClickedOnCallsView(tag: TagModel) {
    this.heap.track('call_tag_clicked_calls_view', { tagName: tag.name })
  }

  viewCallsWithTagOnChatsView(tag: TagModel) {
    this.heap.track('view_calls_with_tag_chats_view', { tagName: tag.name })
  }

  viewCallsWithTagOnCallsView(tag: TagModel) {
    this.heap.track('view_calls_with_tag_calls_view', { tagName: tag.name })
  }

  viewCallsWithTagOnSettings(tag: TagModel) {
    this.heap.track('view_calls_with_tag_settings', { tagName: tag.name })
  }

  goToTagSettingsOnChatsView(tag: TagModel) {
    this.heap.track('go_to_tag_settings_chats_view', { tagName: tag.name })
  }

  goToTagSettingsOnCallsView(tag: TagModel) {
    this.heap.track('go_to_tag_settings_calls_view', { tagName: tag.name })
  }

  removeTagOnChatsView(tag: TagModel) {
    this.heap.track('remove_tag_chats_view', { tagName: tag.name })
  }

  removeTagOnCallsView(tag: TagModel) {
    this.heap.track('remove_tag_calls_view', { tagName: tag.name })
  }

  removeTagOnSettings(tag: TagModel) {
    this.heap.track('remove_tag_settings', { tagName: tag.name })
  }

  addCallTag({ name, description }: { name: string; description: string }) {
    this.heap.track('add_call_tag', {
      tagName: name,
      tagDescription: description,
    })
  }

  editCallTag({
    previousTagName,
    previousTagDescription,
    newTagName,
    newTagDescription,
  }: {
    previousTagName: string
    previousTagDescription: string
    newTagName: string
    newTagDescription: string
  }) {
    this.heap.track('edit_call_tag', {
      previousTagName,
      previousTagDescription,
      newTagName,
      newTagDescription,
    })
  }

  toggleCallTag(enabled: boolean) {
    this.heap.track('toggle_call_tag', { enabled })
  }
}
