import type { WindowEvent } from '@openphone/desktop-client'
import { action, makeObservable, observable } from 'mobx'

import type AppStore from '@src/app/AppStore'
import type IUiStore from '@src/app/IUiStore'
import { DisposeBag } from '@src/lib/dispose'

export default class WindowsTitleBarController implements IUiStore {
  maximized = false
  minimized = false
  fullScreen = false

  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    makeObservable<this, 'handleWindowEvent'>(this, {
      maximized: observable.ref,
      minimized: observable.ref,
      fullScreen: observable.ref,
      handleWindowEvent: action,
    })

    app.electron?.on('window', this.handleWindowEvent)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    app.electron?.window.isMaximized?.().then(action((max) => (this.maximized = max)))
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    app.electron?.window.isMinimized?.().then(action((min) => (this.minimized = min)))
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    app.electron?.window
      .isFullScreen?.()
      .then(action((isFullScreen) => (this.fullScreen = isFullScreen)))

    this.disposeBag.add(() => app.electron?.off('window', this.handleWindowEvent))
  }

  maximize = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.electron?.window.maximize?.()
  }

  unmaximize = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.electron?.window.unmaximize?.()
  }

  minimize = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.electron?.window.minimize?.()
  }

  close = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.app.electron?.window.close?.()
  }

  tearDown = () => {
    this.disposeBag.dispose()
  }

  private handleWindowEvent = (event: WindowEvent) => {
    switch (event.type) {
      case 'maximize':
        this.maximized = true
        return
      case 'unmaximize':
        this.maximized = false
        return
      case 'minimize':
        this.minimized = true
        return
      case 'restore':
        this.minimized = false
        return
      case 'enter-fullscreen':
        this.fullScreen = true
        return
      case 'leave-fullscreen':
        this.fullScreen = false
        return
    }
  }
}
