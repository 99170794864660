import { useEffect, useState } from 'react'

export default function useInstance<T>(
  fn: (previousInstance: T | null) => T,
  deps: any[] = [],
): T {
  const [instance, setInstance] = useState<T>(() => fn(null))

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  useEffect(() => setInstance(fn(instance)), deps)

  return instance
}
