import { mergeRefs } from '@react-aria/utils'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { ForwardedRef } from 'react'
import { forwardRef, useEffect, useRef } from 'react'

import { useFormField } from '@ui/FormField'
import type CommonTextInputProps from '@ui/common/CommonTextInputProps'
import commonTextInputClasses from '@ui/shared/commonTextInputClasses'

import * as styles from './TextInput.css'

/**
 * @deprecated
 */
export type DeprecatedInputSize = 20 | 24 | 48

export type BaseHtmlInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'ref' | 'size' | 'label'
>

export interface TextInputProps
  extends Omit<CommonTextInputProps, 'size'>,
    BaseHtmlInputProps {
  size?: CommonTextInputProps['size'] | DeprecatedInputSize

  /**
   * Automatically selects the whole content when mounts
   */
  autoSelect?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      fullWidth,
      variant = 'outlined',
      size = 45,
      hasError = false,
      autoSelect,
      style,
      ...props
    },
    outerRef,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    const mergedRefs = mergeRefs(inputRef, outerRef)
    const { htmlProps, fieldProps } = useFormField({
      fieldProps: {
        hasError,
      },
    })
    useAutoSelect(!!autoSelect, inputRef)

    const classes = commonTextInputClasses({
      size,
      fullWidth,
      variant,
      hasError: fieldProps.hasError,
    })

    return (
      <input
        {...props}
        {...htmlProps}
        ref={mergedRefs}
        style={{ ...style, ...assignInlineVars({ [styles.size]: `${size}px` }) }}
        className={cx(className, styles.root, ...classes)}
      />
    )
  },
)

export default TextInput

function useAutoSelect(enabled: boolean, ref: ForwardedRef<HTMLInputElement>) {
  const didSelectRef = useRef(false)

  useEffect(() => {
    if (didSelectRef.current) {
      return
    }

    const scheduledAutoSelectionTimeout = setTimeout(() => {
      if (
        !enabled ||
        didSelectRef.current ||
        typeof ref === 'function' ||
        !ref?.current
      ) {
        return
      }

      ref.current.select()
      didSelectRef.current = true
    })

    return () => clearTimeout(scheduledAutoSelectionTimeout)
  }, [enabled, ref])
}
