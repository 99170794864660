import { Routes, Route, Navigate } from 'react-router-dom'

import { BannersProvider } from '@src/app/banner'
import Banners from '@src/app/banner/Banners'
import OutageBanner from '@src/app/banner/OutageBanner'
import DesktopDraggableArea from '@src/app/components/DesktopDraggableArea'
import { useStorePartnerStackKey } from '@src/app/onboarding/partnerStack'
import { UniversalLogin, UniversalLoginSignup } from '@src/app/universal-login'
import UniversalLoginCouponRoute from '@src/app/universal-login/UniversalLoginCouponRoute'

import UniversalLoginAuthorize from './UniversalLoginAuthorize'
import UniversalLoginReauthenticateInfoModal from './UniversalLoginReauthenticateInfoModal'
import * as styles from './UniversalLoginRoute.css'
import UniversalLoginSso from './UniversalLoginSso'

function UniversalLoginRoute() {
  useStorePartnerStackKey()

  return (
    <>
      <BannersProvider>
        <Banners>
          <OutageBanner />
        </Banners>
      </BannersProvider>
      <DesktopDraggableArea className={styles.root}>
        <Routes>
          <Route path="signup" element={<UniversalLoginSignup />} />
          <Route path="login" element={<UniversalLogin />} />
          <Route path="coupon/:couponId" element={<UniversalLoginCouponRoute />} />
          <Route path="auth/sso" element={<UniversalLoginSso />} />
          <Route path="auth/authorize" element={<UniversalLoginAuthorize />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </DesktopDraggableArea>
      <UniversalLoginReauthenticateInfoModal />
    </>
  )
}

export default UniversalLoginRoute
