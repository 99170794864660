import uuid from '@src/lib/uuid'

import type { ActivityId, LoadMoreActivityId } from './ActivityModel'

export function createActivityId(): ActivityId {
  const id = `AC${uuid()}`.replace(/-/g, '')
  if (validateActivityId(id)) {
    return id
  }
  throw new Error(`Constructed an invalid ActivityID. Failing id: ${id}`)
}

export function createLoadMoreActivityId(type: 'before' | 'after'): LoadMoreActivityId {
  const id = `AC${uuid()}`.replace(/-/g, '').concat(`-${type}`)
  if (validateLoadMoreActivityId(id)) {
    return id
  }
  throw new Error(`Constructed an invalid LoadMoreActivityID. Failing id: ${id}`)
}

/**
 * An v4 uuid is 36 characters long, without the dashes is 32 (has 4 groups)
 * so it is 32 characters long. We prefix activity ids with the `AC` identifier.
 * Therefore, a valid activity id is one that starts with AC and then has an
 * alphanumeric 32 character long string (without uppercase characters).
 */
export function validateActivityId(id: string): id is ActivityId {
  return /^AC[a-z0-9]{32}$/.test(id)
}
export function validateLoadMoreActivityId(id: string): id is LoadMoreActivityId {
  return /^AC[a-z0-9]{32}-(after|before)$/.test(id)
}

export function convertLoadMoreActivityIdToNormalActivityId(
  loadMoreId: LoadMoreActivityId,
): ActivityId {
  const normalId = loadMoreId.split('-')[0]
  // @ts-expect-error unchecked index access
  if (validateActivityId(normalId)) {
    return normalId
  }

  throw new Error('Could not convert from a LoadMoreActivityId to a normal ActivityId')
}
