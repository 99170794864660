import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { useAppStore } from '@src/app/context'
import { LoadingIndicator } from '@ui/LoadingIndicator'

import * as styles from './UniversalLoginSso.css'

const UniversalLoginSso = () => {
  const { service } = useAppStore()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    service.authV2.auth0Client?.loginWithRedirect({
      authorizationParams: {
        prompt: 'none',
        connection: 'email',
      },
    })
  }, [service.authV2.auth0Client])

  return (
    <div className={styles.root}>
      <LoadingIndicator size={30} variant="circular" color="primary" />
    </div>
  )
}

export default observer(UniversalLoginSso)
