import { chain, mergeProps } from '@react-aria/utils'
import type { Node } from '@react-types/shared'
import cx from 'classnames'
import type { ReactNode } from 'react'
import { useEffect, useRef, useId } from 'react'
import {
  useFocusRing,
  useGridListItem,
  useGridListSelectionCheckbox,
  useHover,
} from 'react-aria'

import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import Checkbox from '@ui/Checkbox'
import { useComboboxList } from '@ui/Combobox/ComboboxList'
import { getFirstValidItemKey } from '@ui/Combobox/ComboboxList/getNextValidItemKey'
import MenuListItemBase, { type MenuListItemBaseProps } from '@ui/Menu/MenuListItemBase'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'
import { CheckBoxTickIcon } from '@ui/icons/tint/16/general'

import * as styles from './ComboboxListItem.css'

interface ItemNodeProps {
  /**
   * If true the item can't be selected, focused or activated.
   */
  disabled?: boolean

  /**
   * The variant to apply to the item:
   *
   * - `default` displays an ordinary item
   * - `danger` displays the item with more use of the red color to convey a desctuctive action. For example, it can be used in a "Delete" item.
   * - `selected` displays the item with a purpleish background
   *
   * @default `default`
   */
  variant?: MenuListItemBaseProps['variant']

  /**
   * The left icon to display on the item
   *
   * @example
   * ```tsx
   * <Combobox.ListItem icon={<XIcon />}>
   *   Close window
   * </Combobox.ListItem>
   * ```
   */
  icon?: ReactNode

  /**
   * Additional content to display on the right side of the item before the Checkbox
   *
   * @example
   * ```tsx
   * <Combobox.ListItem rightLabel="(555) 555-5555">
   *   Phone Number
   * </Combobox.ListItem>
   * ```
   */
  rightLabel?: string

  /**
   * Description of the list item.
   *
   * If specified a tooltip will be displayed on the left side of the list item containing the value of this property. A visually hidden element is also rendered to provide this description to screen reader users that do not interact with the list item
   *
   */
  description?: string

  /**
   * Whether or not to hide the checkmark/checkbox in the list item
   */
  hideSelectionIndicator?: boolean

  /**
   * Whether or not to hide the checkbox in the list item (show just the checkmark)
   */
  hideCheckbox?: boolean

  /**
   * Whether or not to show the tooltip on the list item. If not specified, the tooltip will be shown if the item is not disabled and has a description or the item content is overflowing.
   */
  showTooltip?: boolean
}

interface ItemNode<T> extends Node<T> {
  props?: ItemNodeProps
}

export interface ComboboxItemProps<T> extends ItemNodeProps {
  item: ItemNode<T>
}

const ComboboxListItem = <T extends object>({ item }: ComboboxItemProps<T>) => {
  const ref = useRef<HTMLLIElement>(null)
  const { state, sensor, setDisabledKey, isVisible, comboboxInputRef } = useComboboxList()
  const { selectionMode } = state.selectionManager

  const firstCollectionKey = state.collection.getFirstKey()
  const firstCollectionItem = getFirstValidItemKey(state, firstCollectionKey)
  const isFirstItem = firstCollectionItem === item.key

  const checkBoxId = useId()
  const listItemContentId = useId()

  useEffect(() => {
    if (item.props?.disabled) {
      setDisabledKey(item.key)
    }
  }, [item, setDisabledKey])

  const { rowProps, gridCellProps, isDisabled, isSelected, descriptionProps } =
    useGridListItem({ node: item }, state, ref)

  const { focusProps, isFocusVisible } = useFocusRing()

  const onKeyDown = (event: KeyboardEvent) => {
    if (!isFirstItem || !comboboxInputRef.current || event.key !== 'ArrowUp') {
      return
    }
    comboboxInputRef.current.focus()
  }

  const chainedKeyDownCapture = chain(onKeyDown, rowProps.onKeyDownCapture)

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  rowProps.onKeyDownCapture = chainedKeyDownCapture

  const { ref: contentRef, isOverflowing } = useIsOverflowing<HTMLDivElement>()

  const isTooltipDisabled = (() => {
    const showTooltip = item.props?.showTooltip

    if (showTooltip !== undefined) {
      return !showTooltip
    }

    return (!isOverflowing && !item.props?.description) || !!item.props?.disabled
  })()

  const { hoverProps, isHovered } = useHover({
    isDisabled: isTooltipDisabled,
  })

  const props = mergeProps(rowProps, focusProps, hoverProps)

  useEffect(() => {
    if (!isVisible || !ref.current || !isFocusVisible) {
      return
    }
    if (sensor.type !== 'keyboard') {
      return
    }
    ref.current.scrollIntoView({ block: 'center' })
  }, [isFocusVisible, isVisible, sensor])

  const variant: MenuListItemBaseProps['variant'] = (() => {
    if (isSelected) {
      return 'selected'
    }
    return item.props?.variant ?? 'default'
  })()

  const { checkboxProps } = useGridListSelectionCheckbox({ key: item.key }, state)

  const rightSide = (() => {
    if (!item.props?.rightLabel && item.props?.hideSelectionIndicator) {
      return null
    }

    const selectionIndicator = (() => {
      if (item.props?.hideSelectionIndicator) {
        return null
      }

      if (selectionMode === 'single' || item.props?.hideCheckbox) {
        return <CheckBoxTickIcon className={styles.tickIcon({ hidden: !isSelected })} />
      }

      return (
        <Checkbox
          id={checkBoxId}
          onClick={(e) => e.preventDefault()}
          onChange={checkboxProps.onChange}
          checked={!!checkboxProps.isSelected}
          disabled={checkboxProps.isDisabled}
          label="Select"
          aria-labelledby={`${checkBoxId} ${listItemContentId}`}
        />
      )
    })()

    return (
      <div className={styles.rightSide({ selectionMode })}>
        {item.props?.rightLabel ? (
          <Typography
            component="div"
            variant="footnote"
            color={
              variant === 'default' ||
              (variant === 'selected' && selectionMode === 'multiple')
                ? 'textSecondary'
                : 'inherit'
            }
          >
            {item.props.rightLabel}
          </Typography>
        ) : null}
        {selectionIndicator}
      </div>
    )
  })()

  const content = (
    <MenuListItemBase ref={ref} variant={variant} disabled={isDisabled} {...props}>
      <div {...gridCellProps} className={styles.cell}>
        {item.props?.icon ? (
          <div className={cx(styles.leftSide({ isDefault: variant === 'default' }))}>
            {item.props.icon}
          </div>
        ) : null}

        <Typography
          component="div"
          variant="footnote"
          className={styles.content}
          id={listItemContentId}
        >
          <div className={styles.ellipsis} ref={contentRef}>
            {item.rendered}
          </div>
        </Typography>

        {rightSide}

        {item.props?.description ? (
          <VisuallyHidden>
            <span id={descriptionProps.id}>{item.props.description}</span>
          </VisuallyHidden>
        ) : null}
      </div>
    </MenuListItemBase>
  )

  if (!isTooltipDisabled) {
    return (
      <Tooltip
        open={isHovered}
        title={isOverflowing ? item.textValue : item.props?.description ?? ''}
        body={
          isOverflowing && item.props?.description ? item.props.description : undefined
        }
        disabled={isTooltipDisabled}
        placement="left"
        offset={10}
        className={styles.description}
      >
        {content}
      </Tooltip>
    )
  }

  return content
}

export default ComboboxListItem
