import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type IntegrationStore from '@src/service/integration-store'
import type { Model } from '@src/service/model/base'
import type { IntegrationEvent } from '@src/service/transport/integration'

class SlackIntegrationModel implements Model {
  id = ''
  type: 'slack' = 'slack' as const
  events: Partial<IntegrationEvent> = {}
  phoneNumberId: string | null = null
  createdAt: number | null = null
  configureUrl: string | null = null
  channel: string | null = null

  constructor(private integrationStore: IntegrationStore) {
    makeAutoObservable(this, {})
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.integrationStore.updateLegacy(this)
  }

  delete() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.integrationStore.deleteLegacy(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      events: toJS(this.events),
      phoneNumberId: this.phoneNumberId,
      createdAt: this.createdAt,
      configureUrl: this.configureUrl,
      channel: this.channel,
    }
  }
}

export default SlackIntegrationModel
