import cx from 'classnames'
import { useMemo } from 'react'

import Typography from '@ui/Typography'
import { XIcon } from '@ui/icons/tint/16/general'

import * as styles from './Callout.css'

export interface CalloutProps {
  id?: string

  /**
   * Type of the callout.
   *
   * @default `gray`
   */
  variant?: styles.CalloutVariant

  /**
   * Depending on the layout, the icon will expect a size of 16px or 18px.
   *
   * For callouts with title and children, we expect a size of 18px.
   * For callouts without title, we expect a size of 16px.
   *
   * @example 📁
   */
  icon?: React.ReactNode
  children: React.ReactNode
  title?: string
  className?: string
  /**
   * If provided, the callout will contain a close button. This property overrides the `action` prop.
   */
  onClose?: () => void

  /**
   * Can't be used with `onClose`.
   */
  action?: React.ReactNode

  /**
   * The placement of the action node.
   *
   * @default 'right'
   */
  actionPlacement?: 'right' | 'bottom'

  /**
   * The component that will wrap the children. If none is provided it will default to `<p>`.
   */
  childComponent?: React.ElementType
}

const Callout = ({
  id,
  variant = 'gray',
  icon,
  title,
  children,
  onClose,
  action,
  actionPlacement = 'right',
  className,
  childComponent,
}: CalloutProps) => {
  const ChildComponent = childComponent ?? 'p'

  const closeButton = onClose ? (
    <button
      type="button"
      aria-label="Close"
      onClick={onClose}
      className={styles.closeButton({ variant })}
    >
      <XIcon aria-hidden="true" />
    </button>
  ) : null

  const layout = useMemo((): styles.CalloutLayout => {
    if (icon && title && children) {
      return 'complete'
    }

    if (icon && children) {
      return 'iconAndChildren'
    }

    if (children) {
      return 'childrenOnly'
    }

    return 'complete'
  }, [children, title, icon])

  return (
    <div id={id} className={cx(styles.root({ variant, layout }), className)}>
      {icon ? (
        <span className={styles.emoji({ layout })} aria-hidden="true">
          {icon}
        </span>
      ) : null}

      <div className={styles.content({ actionPlacement })}>
        <div className={styles.text}>
          {title ? (
            <Typography
              variant="callout"
              fontWeight="medium"
              className={styles.title({ variant })}
            >
              {title}
            </Typography>
          ) : null}

          <ChildComponent
            className={styles.paragraph({ variant, hasTitle: String(!!title), layout })}
          >
            {children}
          </ChildComponent>
        </div>

        {action && !closeButton ? <div className={styles.action}>{action}</div> : null}
      </div>
      {closeButton}
    </div>
  )
}

export default Callout
