/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import type { Plan } from '@src/app/billing/pricing/PricingController'
import type {
  MissingFeature,
  NoLongerNeededReason,
  Reason,
  TechnicalIssue,
  TechnicalIssueLocation,
  TextingIssue,
} from '@src/app/command/CancelSubscriptionCommand/options'
import type { ClientSecret } from '@src/component/CreditCard/creditCardTypes'
import config from '@src/config'
import type { BusinessPreviewTrigger } from '@src/service/analytics/WorkspaceAnalyticsStore'
import type { EncodableSubscription } from '@src/service/model'
import { type CodableCapability } from '@src/service/model/Capability'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface Coupon {
  id?: string
  local?: boolean
  object: 'coupon'
  amount_off: number
  created: number
  currency: string
  duration: 'repeating' | 'forever' | 'once'
  duration_in_months: number
  max_redemptions: number
  metadata: any
  name: string
  percent_off: number
  redeem_by: string
  times_redeemed: string
  valid: boolean
}

type InvoiceStripeStatus =
  | 'deleted'
  | 'draft'
  | 'open'
  | 'paid'
  | 'uncollectible'
  | 'void'

export interface Invoice {
  id: string
  local?: boolean
  object: 'invoice'
  account_country: string | null
  account_name: string | null
  amount_due: number
  amount_paid: number
  amount_remaining: number
  application_fee_amount: number | null
  attempt_count: number
  attempted: boolean
  auto_advance?: boolean
  created: number
  currency: string
  customer_email: string | null
  customer_name: string | null
  customer_phone: string | null
  deleted?: void
  description: string | null
  discount?: {
    id: string
    object: 'discount'
    checkout_session: string
    coupon: Coupon
    customer: string
    end: number
    invoice: string
    invoice_item: string
    promotion_code: string
    start: number
    subscription: string
  }
  due_date: number | null
  ending_balance: number | null
  footer: string | null
  hosted_invoice_url?: string | null
  invoice_pdf?: string | null
  lines: {
    data: {
      id: string
      object: string
      amount: number
      amount_excluding_tax: number
      currency: string
      description: string
      discountable: boolean
      discounts: []
      livemode: boolean
      period: {
        end: number
        start: number
      }
      price: {
        id: string
        object: string
        active: boolean
        billing_scheme: string
        created: number
        currency: string
        custom_unit_amount: null
        livemode: boolean
        lookup_key: null
        nickname: string
        product: string
        recurring: {
          aggregate_usage: null
          interval: string
          interval_count: number
          trial_period_days: number
          usage_type: string
        }
        tax_behavior: string
        tiers_mode: null
        transform_quantity: null
        type: string
        unit_amount: number
        unit_amount_decimal: string
      }
      proration: boolean
      proration_details: {
        credited_items: null
      }
      plan: {
        id: string
        object: string
        active: boolean
        aggregate_usage: null
        amount: number
        amount_decimal: string
        billing_scheme: string
        created: number
        currency: number
        interval: 'month' | 'year'
        interval_count: number
        livemode: boolean
        metadata: object
        nickname: string
        product: string
        tiers_mode: null
        transform_usage: null
        trial_period_days: number
        usage_type: string
      }
      quantity: number
      subscription: string
      subscription_item: string
      tax_amounts: {
        amount: number
        tax_rate: string
      }[]
      tax_rates: {
        display_name: string
        id: string
      }[]
      type: string
      unit_amount_excluding_tax: string
    }[]
    has_more: boolean
    total_count: number
  }
  livemode: boolean
  metadata: any
  next_payment_attempt: number | null
  number: string | null
  paid: boolean
  period_end: number
  period_start: number
  post_payment_credit_notes_amount: number
  pre_payment_credit_notes_amount: number
  receipt_number: string | null
  starting_balance: number
  statement_descriptor: string | null
  status: InvoiceStripeStatus
  subscription: string
  subscription_proration_date?: number
  subtotal: number
  tax: number | null
  tax_percent: number | null
  total: number
  webhooks_delivered_at: number | null
}

export interface CreditCard {
  id?: string
  local?: boolean
  object: 'card'
  address_city: string
  address_country: string
  address_line1: string
  address_line1_check: string
  address_line2: string
  address_state: string
  address_zip: string
  address_zip_check: string
  brand: string
  country: string
  customer: string
  cvc_check: string
  dynamic_last4: string
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  last4: string
  metadata: any
  name: string
  tokenization_method: string
}

export type UpdateParams = {
  plan: Plan
  annual: boolean
  coupon?: string
}

export type Intent = {
  clientSecret: ClientSecret
}

export type CancelParams = { feedback: string } & (
  | {
      reason: Extract<Reason, 'cannot use with other service'>
      subreason: {
        incompatibleService: string
      }
    }
  | {
      reason: Extract<Reason, 'technical issues'>
      subreason: {
        technicalIssues: TechnicalIssue[]
        otherTechnicalIssue: string
        technicalIssueLocations: TechnicalIssueLocation[]
      }
    }
  | {
      reason: Extract<Reason, 'missing features'>
      subreason: {
        missingFeatures: MissingFeature[]
        otherMissingFeature: string
      }
    }
  | {
      reason: Extract<Reason, 'no longer needed'>
      subreason: {
        noLongerNeededReason: NoLongerNeededReason
        otherNoLongerNeededReason: string
      }
    }
  | {
      reason: Extract<Reason, 'texting issues'>
      subreason: {
        textingIssues: TextingIssue[]
      }
    }
  | {
      reason: Extract<Reason, 'other'>
      subreason: {
        otherReason: string
      }
    }
)

export interface InvoicePreviewParams {
  annual: boolean
  plan: Plan
  address?: {
    city: string
    country: string
    line1: string
    line2: string
    postal_code: string
    state: string
  }
}

export interface PaginatedInvoiceParams {
  after?: string
  unpaid?: boolean
  paginate?: boolean
}

export interface PaginatedInvoices {
  invoices: Invoice[]
  hasMore: boolean
  startId: string
  endId: string
}

export interface StartBusinessPreviewParams {
  trigger: BusinessPreviewTrigger
  enableCallSummaries: boolean
  enableCallRecordings: boolean
}

export interface StartBusinessPreviewResponse {
  subscription: EncodableSubscription
  capabilities: CodableCapability[]
}

export default class BillingClient {
  constructor(private transport: Transport) {}

  capabilities(): Promise<CodableCapability[]> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}capability` }),
    )
  }

  subscription(): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}subscription` }),
    )
  }

  identityVerificationSecret(): Promise<{ identityVerificationSecret: string }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/setupKYC`,
      }),
    )
  }

  invoicePreview(body: InvoicePreviewParams): Promise<Invoice> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'POST',
        url: `${config.BILLING_SERVICE_URL}subscription/preview`,
        body,
      }),
    )
  }

  upcomingInvoice(): Promise<Invoice> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}invoice/upcoming` }),
    )
  }

  update(params: UpdateParams): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/update`,
        body: params,
      }),
    )
  }

  getSubscriptionIntent(): Promise<Intent> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/setupIntent`,
      }),
    )
  }

  getPaymentIntent(): Promise<Intent> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/mobilea2p`,
      }),
    )
  }

  convertCredits(amount: number): Promise<{ subscription: EncodableSubscription }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/convert`,
        body: { amount },
      }),
    )
  }

  addCredits(amount: number): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/credit`,
        body: { amount },
      }),
    )
  }

  autoCharge(amount: number): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/autoCharge`,
        body: { amount },
      }),
    )
  }

  reactivate(): Promise<EncodableSubscription | Intent> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'POST',
        url: `${config.BILLING_SERVICE_URL}subscription/reactivate`,
      }),
    )
  }

  start(params: UpdateParams): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/start`,
        body: params,
      }),
    )
  }

  startFallback(params: UpdateParams): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
        body: params,
      }),
    )
  }

  endTrial(): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/endTrial`,
      }),
    )
  }

  cancel(params: CancelParams): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/cancel`,
        body: params,
      }),
    )
  }

  creditCard(): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}card` }),
    )
  }

  upsertCreditCard(paymentMethod: string): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}card`,
        body: { paymentMethod },
      }),
    )
  }

  invoices(
    params: PaginatedInvoiceParams & { paginate?: true },
  ): Promise<PaginatedInvoices>
  invoices(params: PaginatedInvoiceParams & { paginate: false }): Promise<Invoice[]>
  invoices({
    after,
    unpaid,
    paginate = true,
  }: PaginatedInvoiceParams): Promise<PaginatedInvoices | Invoice[]> {
    const params = new URLSearchParams()

    if (after) {
      params.append('after', after)
    }

    if (unpaid) {
      params.append('unpaid', 'true')
    }

    if (paginate) {
      params.append('paginate', 'true')
    }

    return this.transport.queue(
      new HttpTransaction({
        url: `${config.BILLING_SERVICE_URL}invoice?${params.toString()}`,
      }),
    )
  }

  coupon(code: string): Promise<Coupon> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}coupon/${code}` }),
    )
  }

  declineKyc(): Promise<EncodableSubscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/declineKyc`,
      }),
    )
  }

  payInvoice(id: string): Promise<Intent & { paymentMethod: string }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}invoice/${id}`,
      }),
    )
  }

  startBusinessPreview(
    params: StartBusinessPreviewParams,
  ): Promise<StartBusinessPreviewResponse> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}preview/startPreview`,
        body: params,
      }),
    )
  }
}
