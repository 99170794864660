import { makeAutoObservable, toJS } from 'mobx'

import type { EventTypes } from '@src/data'
import uuid from '@src/lib/uuid'
import type WorkspaceStore from '@src/service/WorkspaceStore'

import type { Model } from '.'

export interface CodableWebhook {
  id: string
  object: string | null
  label: string | null
  url: string
  triggers: TriggerType[]
  key: string | null
  setting: 'enabled' | 'disabled' | null
  apiVersion: string | null
  createdAt: string | null
  updatedAt: string | null
  etag: string
}

export interface TriggerType {
  category: 'activities' | 'contacts'
  events: (EventTypes | '*')[]
  resourceIds: string[] | ['*']
}

class WebhookModel implements CodableWebhook, Model {
  id = `WH${uuid()}`.replace(/-/g, '')
  object: string | null = null
  label: string | null = null
  url = ''
  triggers: TriggerType[] = []
  key: string | null = null
  setting: 'enabled' | 'disabled' | null = null
  apiVersion: string | null = null
  createdAt: string | null = null
  updatedAt: string | null = null
  etag = ''

  constructor(
    private workspaceStore: WorkspaceStore,
    attrs: Partial<CodableWebhook> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {}, { autoBind: true })
  }

  get enabled() {
    return this.setting === 'enabled'
  }

  set enabled(value: boolean) {
    this.setting = value ? 'enabled' : 'disabled'
  }

  get activitiesTrigger() {
    return (
      this.triggers.find((t) => t.category === 'activities') ?? {
        category: 'activities',
        events: [],
        resourceIds: [],
      }
    )
  }

  get contactsTrigger() {
    return (
      this.triggers.find((t) => t.category === 'contacts') ?? {
        category: 'contacts',
        events: [],
        resourceIds: [],
      }
    )
  }

  save() {
    this.workspaceStore.webhooks.put(this)

    return this.workspaceStore
      .updateWebhook(this.serialize())
      .then(this.deserialize)
      .catch((error) => {
        if (
          error instanceof Error &&
          'data' in error &&
          error.data !== null &&
          typeof error.data === 'object' &&
          'code' in error.data &&
          error.data.code === 'ENABLE_BAD_URL'
        ) {
          this.enabled = false
        }

        // when any error received, we need to increment the etag
        // otherwise, the next API update call will fail
        // for more details https://linear.app/openphone/issue/WORK-3257/increment-etag-if-any-webhook-error-is-received
        this.etag = `${parseInt(this.etag) + 2}`

        throw error
      })
  }

  delete() {
    this.workspaceStore.webhooks.delete(this)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.workspaceStore.deleteWebhook(this.serialize())
  }

  deserialize(json: Partial<CodableWebhook>) {
    Object.assign(this, json)
    return this
  }

  serialize(): CodableWebhook {
    return {
      id: this.id,
      object: this.object,
      label: this.label,
      url: this.url,
      triggers: toJS(this.triggers),
      key: this.key,
      setting: this.setting,
      apiVersion: this.apiVersion,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      etag: this.etag,
    }
  }
}

export default WebhookModel
