import cx from 'classnames'
import type { ReactNode } from 'react'

import Typography from '@ui/Typography'

import * as styles from './CommandHeader.css'

interface CommandHeaderProps {
  title: ReactNode
  titleId?: string
  subtitle?: ReactNode
  className?: string
}

const CommandHeader = function ({
  className,
  title,
  titleId,
  subtitle,
}: CommandHeaderProps) {
  return (
    <div className={cx(styles.root, className)}>
      {typeof title === 'string' ? (
        <Typography id={titleId} variant="title3" color="textPrimary" fontWeight="medium">
          {title}
        </Typography>
      ) : (
        <div id={titleId}>{title}</div>
      )}
      {subtitle ? (
        <div className={styles.subtitle}>
          {typeof subtitle === 'string' ? (
            <Typography variant="body" color="textSecondary">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </div>
      ) : null}
    </div>
  )
}

export default CommandHeader
