import { makeObservable, observable, reaction } from 'mobx'

import type {
  RawCompleteTollFreeRegistration,
  LiteTollFreeRegistration,
} from '@src/app/settings/trust/trustTypes'
import StatefulPromise from '@src/lib/StatefulPromise'
import { DisposeBag } from '@src/lib/dispose'
import type {
  TollFreeRegistrationCreateRequest,
  TollFreeRegistrationSubmitRequest,
  TollFreeRegistrationUpdateRequest,
} from '@src/service'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import { TollFreeRegistrationLiteModel, TollFreeRegistrationModel } from './model'
import type TollFreeRegistrationLiteRepository from './worker/repository/TollFreeRegistrationLiteRepository'
import { TOLL_FREE_REGISTRATION_LITE_TABLE_NAME } from './worker/repository/TollFreeRegistrationLiteRepository'
import type TollFreeRegistrationRepository from './worker/repository/TollFreeRegistrationRepository'
import { TOLL_FREE_REGISTRATION_TABLE_NAME } from './worker/repository/TollFreeRegistrationRepository'

export default class TollFreeRegistrationStore {
  private readonly disposeBag = new DisposeBag()

  private fetchTollFreeRegistrationPromise = new StatefulPromise(
    this.handleFetch.bind(this),
  )

  private fetchTollFreeRegistrationLitePromise = new StatefulPromise(
    this.handleFetchLite.bind(this),
  )

  private readonly collection: PersistedCollection<
    TollFreeRegistrationModel,
    TollFreeRegistrationRepository
  >

  private readonly collectionLite: PersistedCollection<
    TollFreeRegistrationLiteModel,
    TollFreeRegistrationLiteRepository
  >

  constructor(private readonly root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(TOLL_FREE_REGISTRATION_TABLE_NAME),
      classConstructor: (data: RawCompleteTollFreeRegistration) =>
        new TollFreeRegistrationModel(data),
    })

    this.collectionLite = new PersistedCollection({
      table: root.storage.table(TOLL_FREE_REGISTRATION_LITE_TABLE_NAME),
      classConstructor: (data: LiteTollFreeRegistration) =>
        new TollFreeRegistrationLiteModel(data),
    })

    makeObservable<this, 'collection' | 'collectionLite'>(this, {
      collection: observable,
      collectionLite: observable,
    })

    this.disposeBag.add(
      this.subscribeToWebSocket(),
      reaction(
        () => root.user.current?.asMember?.isAdmin,
        () => {
          // Reset fetch status when user becomes admin or loses admin status
          // This is to ensure that the fetch is re-triggered again when needed
          this.fetchTollFreeRegistrationPromise.resetStatus()
          this.fetchTollFreeRegistrationLitePromise.resetStatus()
        },
      ),
    )
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private handleFetch() {
    return this.root.transport.trust.tollFreeRegistration.fetch()
  }

  async fetch() {
    if (this.fetchStatus === 'idle') {
      const response = await this.fetchTollFreeRegistrationPromise.run()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.collection.load(response)
    }
  }

  get fetchStatus() {
    return this.fetchTollFreeRegistrationPromise.status
  }

  private handleFetchLite() {
    return this.root.transport.trust.tollFreeRegistration.fetchLite()
  }

  async fetchLite() {
    if (this.fetchLiteStatus === 'idle') {
      const response = await this.fetchTollFreeRegistrationLitePromise.run()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.collectionLite.load(response)
    }
  }

  get fetchLiteStatus() {
    return this.fetchTollFreeRegistrationLitePromise.status
  }

  async update(fields: TollFreeRegistrationUpdateRequest) {
    if (!fields.id) {
      return null
    }

    const registration = this.collection.get(fields.id)

    if (!registration) {
      return null
    }

    const json = await this.root.transport.trust.tollFreeRegistration.update({
      ...fields,
      id: registration.id,
    })

    return registration.localUpdate(json)
  }

  async submit(fields: TollFreeRegistrationSubmitRequest) {
    if (!fields.id) {
      return null
    }

    const registration = this.collection.get(fields.id)

    if (!registration) {
      return null
    }

    const json = await this.root.transport.trust.tollFreeRegistration.submit({
      ...fields,
    })

    return registration.localUpdate(json)
  }

  async create(raw: TollFreeRegistrationCreateRequest) {
    const json = await this.root.transport.trust.tollFreeRegistration.create(raw)

    const registration = new TollFreeRegistrationModel(json)
    this.collection.put(registration)

    return registration
  }

  async delete(id: string) {
    const registration = this.collection.get(id)

    if (!registration) {
      return
    }

    await this.root.transport.trust.tollFreeRegistration.delete(registration.id)
    this.collection.delete(registration.id)
    this.collectionLite.delete(registration.id)
  }

  get list() {
    return this.collection.list
  }

  getById(id: string) {
    return this.collection.get(id)
  }

  getLiteById(id: string) {
    return this.collectionLite.get(id)
  }

  private subscribeToWebSocket() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        // when customer deletes a phone number, remove the TFN registration
        case 'phone-number-delete':
          this.collection.delete(data.phoneNumberId)
          this.collectionLite.delete(data.phoneNumberId)
          break

        // when customer deletes a TFN registration
        // note that there's no current websocket event for this
        case 'tfn-reg-delete':
          this.collection.delete(data.tfnRegistrationId)
          this.collectionLite.delete(data.tfnRegistrationId)
          break

        // when customer updates the details of a TFN registration
        case 'tfn-reg-update': {
          // if the updated registration is not in the collection, we don't need to do anything
          const registration = this.collection.get(data.tfnRegistration.id)
          if (registration) {
            // because the business details are shared across registrations, we have to fetch them all again to ensure
            // the other registrations aren't stale
            this.fetchTollFreeRegistrationPromise.resetStatus()
            return this.fetch()
          }
          break
        }

        case 'tfn-reg-create': {
          const registration = new TollFreeRegistrationModel(data.tfnRegistration)
          this.collection.put(registration)

          // to keep full and lite in sync, we should create a new lite registration when a full one is created
          const registrationLite = new TollFreeRegistrationLiteModel({
            id: data.tfnRegistration.id,
            canMessageCANumbers: data.tfnRegistration.canMessageCANumbers,
            canMessageUSNumbers: data.tfnRegistration.canMessageUSNumbers,
            object: data.tfnRegistration.object,
            registrationStatus: data.tfnRegistration.registrationStatus,
          })
          this.collectionLite.put(registrationLite)
          break
        }
      }
    })
  }
}
