import { useEffect } from 'react'
import type { BehaviorSubject, Observable } from 'rxjs'

type ObservableCreator<T> = () => undefined | BehaviorSubject<T> | Observable<T>

export default function useObservable<T>(
  observable: BehaviorSubject<T> | Observable<T> | ObservableCreator<T>,
  handler: (value: T) => void,
  deps: any[] = [],
  _debug?: string,
): void {
  useEffect(() => {
    const obs = typeof observable === 'function' ? observable() : observable
    if (!obs) {
      return
    }
    const subs = obs.subscribe(handler)
    return () => {
      subs.unsubscribe()
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, deps)
}
