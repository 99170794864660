import { useEffect } from 'react'

import { useAppStore } from '@src/app/context'

export default function useEmitFirstRender() {
  const { electron } = useAppStore()

  useEffect(() => {
    async function emitOnFirstRender() {
      try {
        await electron?.window.onFirstRender?.()
      } catch {
        // ignore errors
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    emitOnFirstRender()
  }, [electron])
}
