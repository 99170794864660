import { makeObservable, observable, computed } from 'mobx'

import { parseDate } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type { AccountPortRequestUpsertRequest, AccountPortRequestSubmitRequest } from '.'
import type Service from '.'
import { PortRequest } from './model'
import type { PortRequestRepository } from './worker/repository/PortRequestRepository'

export default class PortRequestStore {
  readonly collection: PersistedCollection<PortRequest, PortRequestRepository>
  private readonly disposeBag = new DisposeBag()

  get drafts(): PortRequest[] {
    return this.collection.list.filter((item) => item.isDraft)
  }

  constructor(private readonly root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table('portRequest'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new PortRequest(json),
      compare: (a, b) => (parseDate(b.savedAt) ?? 0) - (parseDate(a.savedAt) ?? 0),
    })

    makeObservable<this>(this, {
      collection: observable,
      drafts: computed,
    })

    this.disposeBag.add(this.subscribeToWebSocket())

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.load()
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  async fetch() {
    const response = await this.root.transport.account.portRequest.getAll()

    const mergedPortRequests = response.map((rawPortRequest) => {
      const persisted = this.collection.get(rawPortRequest.id)
      if (!persisted) {
        return rawPortRequest
      }
      return {
        ...rawPortRequest,
        rawBillingStatementName: persisted.rawBillingStatementName,
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection.load(mergedPortRequests, {
      deleteOthers: true,
    })
  }

  async upsert(dto: AccountPortRequestUpsertRequest) {
    const billingStatementName = dto.rawBillingStatementName
    delete dto.rawBillingStatementName

    const response = await this.root.transport.account.portRequest.upsert(dto)

    const portRequest = new PortRequest({
      ...response.body,
      rawBillingStatementName: billingStatementName,
    })

    this.collection.put(portRequest)

    return portRequest
  }

  async delete(id: string) {
    const item = this.collection.get(id)

    if (!item) {
      return
    }

    const phoneNumber = this.root.phoneNumber.getByPortRequestId(id)
    const entityPhoneNumber = this.root.organization.phoneNumber.collection.get(
      phoneNumber?.id ?? '',
    )

    if (phoneNumber) {
      this.root.organization.phoneNumber.collection.delete(phoneNumber.id)
      this.root.phoneNumber.collection.delete(phoneNumber.id)
    }

    this.collection.delete(id)

    try {
      await this.root.transport.account.portRequest.delete(id)
    } catch (error) {
      this.collection.put(item)

      if (entityPhoneNumber) {
        this.root.organization.phoneNumber.collection.put(entityPhoneNumber)
      }

      if (phoneNumber) {
        this.root.phoneNumber.collection.put(phoneNumber)
      }

      throw error
    }
  }

  async submit({
    id,
    signatureImageUrl,
  }: Omit<AccountPortRequestSubmitRequest, 'timeZone'>) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    await this.root.transport.account.portRequest.submit({
      id,
      signatureImageUrl,
      timeZone,
    })
  }

  async resubmit(id: string) {
    await this.root.transport.account.portRequest.resubmit(id)
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'port-request-update': {
          const rawBillingStatementName = this.collection.get(data.portRequest.id)
            ?.rawBillingStatementName

          const portRequest = new PortRequest({
            ...data.portRequest,
            rawBillingStatementName,
          })

          this.collection.put(portRequest)
          break
        }
        case 'port-request-delete':
          this.collection.delete(data.portRequest.id)
          break
      }
    })
  }
}
