import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type IntegrationStore from '@src/service/integration-store'
import type { Model } from '@src/service/model/base'
import type { IntegrationEvent } from '@src/service/transport/integration'

export interface EmailIntegrationCodable {
  id: string
  events: Partial<IntegrationEvent>
  phoneNumberId: string | null
  createdAt: number | null
  to: string[]
  type: 'email'
}

class EmailIntegrationModel implements Model {
  id = ''
  events: Partial<IntegrationEvent> = {}
  phoneNumberId: string | null = null
  createdAt: number | null = null
  to: string[] = []

  readonly type: 'email' = 'email' as const

  constructor(private integrationStore: IntegrationStore) {
    makeAutoObservable(this, {})
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.integrationStore.updateLegacy(this)
  }

  delete() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.integrationStore.deleteLegacy(this)
  }

  deserialize = (json: EmailIntegrationCodable) => {
    Object.assign(this, json)

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt)
    }

    return this
  }

  serialize = (): EmailIntegrationCodable => {
    return {
      id: this.id,
      type: this.type,
      events: toJS(this.events),
      phoneNumberId: this.phoneNumberId,
      createdAt: this.createdAt,
      to: toJS(this.to),
    }
  }
}

export default EmailIntegrationModel
