import { mergeRefs } from '@react-aria/utils'
import cx from 'classnames'
import { nanoid } from 'nanoid'
import type { ReactNode, CSSProperties } from 'react'
import { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react'

import type CommonTextInputProps from '@src/design-system/common/CommonTextInputProps'
import TextInput from '@ui/TextInput'
import type {
  TextInputProps,
  BaseHtmlInputProps,
  DeprecatedInputSize,
} from '@ui/TextInput'
import Typography from '@ui/Typography'

import * as styles from './TextField.css'

export interface TextFieldProps
  extends Pick<CommonTextInputProps, 'fullWidth' | 'variant'>,
    BaseHtmlInputProps {
  size?: CommonTextInputProps['size'] | DeprecatedInputSize
  /**
   * The label of the text field
   *
   * @type {string} is passed, then it will be shown with a default styles
   * @type {object} is passed, then some configuration options may apply
   *
   */
  label?: string | { text: string; optional?: boolean }
  /**
   * The class that can be applied to the root element
   */
  className?: string
  /**
   * The style object that can be applied to the root element
   */
  style?: CSSProperties
  /**
   * The helper string that is shown below of the {@link TextInput}
   *
   * Note: if {@link errorText} is provided, the helper string will be ignored in favour of the error message
   */
  helperText?: string
  /**
   * The error message that is shown below of the {@link TextInput}
   */
  errorText?: string
  /**
   * The component that should appear at the end of the {@link TextInput}
   */
  endAdornment?: ReactNode
  /**
   * The component that should appear at the start of the {@link TextInput}
   */
  startAdornment?: ReactNode
  /**
   * @see TextField for implementation details
   */
  textInputProps?: TextInputProps
  /**
   * Automatically selects the whole content when mounts
   */
  autoSelect?: boolean
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      style,
      label,
      fullWidth,
      id,
      helperText,
      errorText,
      endAdornment,
      startAdornment,
      textInputProps,
      ...props
    },
    outerRef,
  ) => {
    const hasError = Boolean(errorText)

    const elementId = useMemo(() => (id ? id : nanoid()), [id])
    const endAdornmentRef = useRef<HTMLDivElement>(null)
    const startAdornmentRef = useRef<HTMLDivElement>(null)
    const [endAdornmentWidth, setEndAdornmentWidth] = useState<number | null>(null)
    const [startAdornmentWidth, setStartAdornmentWidth] = useState<number | null>(null)

    const internalRef = useRef<HTMLInputElement>(null)
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    const ref = mergeRefs<HTMLInputElement>(outerRef, internalRef)

    useLayoutEffect(() => {
      if (internalRef?.current && endAdornmentRef?.current) {
        setEndAdornmentWidth(endAdornmentRef.current.clientWidth)
      }

      if (internalRef?.current && startAdornmentRef?.current) {
        setStartAdornmentWidth(startAdornmentRef.current.clientWidth)
      }
    }, [])

    const textInputStyles = {
      ...(endAdornmentWidth && { paddingRight: endAdornmentWidth }),
      ...(startAdornmentWidth && { paddingLeft: startAdornmentWidth }),
      ...(textInputProps?.style && textInputProps?.style),
    }

    return (
      <div style={style} className={cx(styles.root, className)}>
        {label && (
          <Typography
            variant="caption1"
            color="textSecondary"
            fontWeight="regular"
            component="label"
            htmlFor={elementId}
            className={cx(styles.label({ hasError }), { [styles.fullWidth]: fullWidth })}
          >
            {typeof label === 'string' ? (
              label
            ) : (
              <>
                {label.text}
                {label.optional ? (
                  <Typography variant="caption1" color="placeholder" component="span">
                    &nbsp;(optional)
                  </Typography>
                ) : null}
              </>
            )}
          </Typography>
        )}
        {startAdornment && (
          <div
            className={cx(styles.adornment, styles.startAdornment)}
            ref={startAdornmentRef}
          >
            {startAdornment}
          </div>
        )}
        <TextInput
          id={elementId}
          fullWidth={fullWidth}
          style={textInputStyles}
          ref={ref}
          hasError={hasError}
          {...props}
          {...textInputProps}
        />
        {endAdornment && (
          <div
            className={cx(styles.adornment, styles.endAdornment)}
            ref={endAdornmentRef}
          >
            {endAdornment}
          </div>
        )}
        {(errorText || helperText) && (
          <Typography
            variant="footnote"
            fontWeight="regular"
            component="span"
            className={cx({
              [styles.error]: errorText,
              [styles.helperText]: !errorText,
              [styles.fullWidth]: fullWidth,
            })}
          >
            {errorText || helperText}
          </Typography>
        )}
      </div>
    )
  },
)

export default TextField
