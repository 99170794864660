import type { ButtonProps, Color } from '@ui/Button/Button'

/**
 * Makes sure that the provided color property can be used with the given button variant.
 * If not, it will provide a default color that can be used with that variant.
 */
export default function normalizeColor({
  variant,
  color,
  onlyIcon,
}: Pick<ButtonProps, 'color' | 'variant' | 'onlyIcon'>): Color {
  // there's only a subtle color for the ghost variant and the pastel variant when it's only icon
  // any other combination that tries to use the subtle color will use the default color
  if (
    ((variant !== 'pastel' && variant !== 'ghost') ||
      (variant === 'pastel' && !onlyIcon)) &&
    color === 'subtle'
  ) {
    color = 'default'
  }

  if (variant === 'solid' && color === 'default') {
    return 'purple'
  }

  if (variant === 'outline') {
    return 'default'
  }

  if (variant === 'dashed') {
    return 'default'
  }

  if (variant === 'pastel' && color === 'white') {
    return 'default'
  }

  if (variant === 'ghost' && color === 'white') {
    return 'default'
  }

  return color ?? 'default'
}
