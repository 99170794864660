import { makeAutoObservable, toJS } from 'mobx'

import type { Model } from '@src/service/model'

export interface CodableTag {
  id: string
  name: string
  description: string | null
  emoji: string | null
  color: string | null
  orgId?: string
  phoneNumberId: string
  source: string
  callsLabeled: number
  userId: string | null
  createdAt: string
  updatedAt: string
  archivedAt: string | null
  deletedAt: string | null
}

/**
 * Represents a pointer to a full TagModel from other Models.
 * In this case the ActivityModel holds an array of CodableTagLite
 * that conveys the Activity has these many TagModels.
 */
export interface CodableTagLite {
  id: string
  userId: string | null
  createdAt: string
}

export default class TagModel implements Model, CodableTag {
  private raw: CodableTag

  constructor(tag: CodableTag) {
    this.raw = tag
    makeAutoObservable(this)
  }

  get id(): string {
    return this.raw.id
  }

  get name(): string {
    return this.raw.name
  }

  get description(): string | null {
    return this.raw.description
  }

  get emoji(): string | null {
    return this.raw.emoji ?? '🏷️'
  }

  get color(): string | null {
    return this.raw.color
  }

  get orgId(): string | undefined {
    return this.raw.orgId
  }

  get phoneNumberId(): string {
    return this.raw.phoneNumberId
  }

  get source(): string {
    return this.raw.source
  }

  get callsLabeled(): number {
    return this.raw.callsLabeled
  }

  get userId(): string | null {
    return this.raw.userId
  }

  get createdAt(): string {
    return this.raw.createdAt
  }

  get updatedAt(): string {
    return this.raw.updatedAt
  }

  get archivedAt(): string | null {
    return this.raw.archivedAt
  }

  get deletedAt(): string | null {
    return this.raw.deletedAt
  }

  deserialize(json: Partial<CodableTag>) {
    Object.assign(this.raw, json)
    return this
  }

  serialize(): CodableTag {
    return toJS(this.raw)
  }
}
