import type {
  StepDefinitionId,
  TriggerDefinitionId,
} from '@src/app/components/workflow/builder/WorkflowBuilderController'
import type { RawWorkflowStepDefinition } from '@src/service/model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from '@src/service/model/workflow/WorkflowTriggerDefinitionModel'

import type Heap from './Heap'

type TriggerType = TriggerDefinitionId
type WorkflowType = 'default call flow' | 'forward all calls'
type BlockType = StepDefinitionId | TriggerDefinitionId
type BlockCategory =
  | RawWorkflowStepDefinition['category']
  | RawWorkflowTriggerDefinition['category']

export default class WorkflowAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Tracks when the workflow builder page is viewed.
   *
   * @param has_block_selected whether a block was pre-selected when the builder was viewed
   */
  workflowBuilderViewed(has_block_selected: boolean) {
    this.heap.track('workflow_builder_viewed', {
      has_block_selected,
    })
  }

  /**
   * Tracks when the back button in the workflow builder header is clicked.
   *
   * @param has_unsaved_changes whether the workflow had unsaved changes when the back button was clicked
   */
  workflowBuilderBackButtonClicked(has_unsaved_changes: boolean) {
    this.heap.track('workflow_builder_back_button_clicked', {
      has_unsaved_changes,
    })
  }

  /**
   * Tracks when the enable/disable toggle in the workflow builder header is clicked.
   *
   * @param enabled whether the workflow was enabled or disabled
   * @param workflow_trigger the trigger type of the workflow
   */
  workflowBuilderToggleClicked(enabled: boolean, workflow_trigger: TriggerType) {
    this.heap.track('workflow_builder_toggle_clicked', {
      enabled,
      workflow_trigger,
    })
  }

  /**
   * Tracks when the discard button in the “Unpublished changes” banner is clicked.
   */
  workflowDiscardChangesButtonClicked() {
    this.heap.track('workflow_discard_changes_button_clicked')
  }

  /**
   * Tracks when the publish button in the “Unpublished changes” banner is clicked.
   */
  workflowPublishChangesButtonClicked() {
    this.heap.track('workflow_publish_changes_button_clicked')
  }

  /**
   * Tracks when the zoom level of the workflow canvas is changed.
   *
   * @param zoom_value the new zoom value. Can be a percentage or a keyword like 'fit', 'in', or 'out'
   * @param trigger how the zoom value was changed
   */
  workflowCanvasZoomChanged(
    zoom_value: 'in' | 'out' | 'fit' | string,
    trigger: 'canvas controls' | 'wheel',
  ) {
    this.heap.track('workflow_canvas_zoom_changed', {
      zoom_value,
      trigger,
    })
  }

  /**
   * Tracks when a block on the workflow canvas is clicked.
   *
   * @param block_type the type of block that was clicked
   * @param block_category the category of block that was clicked
   */
  workflowCanvasBlockClicked(block_type: BlockType, block_category: BlockCategory) {
    this.heap.track('workflow_canvas_block_clicked', {
      block_type,
      block_category,
    })
  }

  /**
   * Tracks when the “plus” button that appears on hover is clicked.
   */
  workflowCanvasAddBlockButtonClicked() {
    this.heap.track('workflow_canvas_add_block_button_clicked')
  }

  /**
   * Tracks when a block is added to the workflow canvas.
   *
   * @param block_type the type of block that was added
   * @param block_category the category of block that was added
   * @param source where the block was added from
   */
  workflowCanvasBlockAdded(
    block_type: BlockType,
    block_category: BlockCategory,
    source: 'add menu' | 'drag' | 'next steps',
  ) {
    this.heap.track('workflow_canvas_block_added', {
      block_type,
      block_category,
      source,
    })
  }

  /**
   * Tracks when the context menu for a block is opened.
   *
   * @param trigger how the block’s context menu was opened
   * @param block_type the type of block for which the menu was opened
   */
  workflowCanvasBlockMenuOpened(
    trigger: 'menu button' | 'right click',
    block_type: BlockType,
  ) {
    this.heap.track('workflow_canvas_block_menu_opened', {
      trigger,
      block_type,
    })
  }

  /**
   * Tracks when an option from a block’s context menu is selected.
   *
   * @param action the action that was selected from the block’s context menu
   * @param block_type the type of block for which the option was selected
   */
  workflowCanvasBlockMenuOptionSelected(
    action: 'edit' | 'replace' | 'delete',
    block_type: BlockType,
  ) {
    this.heap.track('workflow_canvas_block_menu_option_selected', {
      action,
      block_type,
    })
  }

  /**
   * Tracks when the users uses a keyboard or command shortcut
   *
   * @param source how the shortcut was triggered
   * @param action the action that was triggered
   */
  workflowCanvasShortcutPerformed(
    source: 'keyboard' | 'command palette',
    action:
      | 'delete block'
      | 'zoom in'
      | 'zoom out'
      | 'zoom to 100'
      | 'fit to view'
      | 'undo'
      | 'redo',
  ) {
    this.heap.track('workflow_canvas_shortcut_performed', {
      source,
      action,
    })
  }

  /**
   * Tracks when the user clicks the undo or redo button in the workflow canvas
   *
   * @param action the action that was triggered
   */
  workflowCanvasHistoryNavigated(action: 'undo' | 'redo') {
    this.heap.track('workflow_canvas_history_navigated', {
      action,
    })
  }

  /**
   * Tracks when a block from the workflow details view of the side panel is dragged.
   *
   * @param block_type the type of block that was dragged
   * @param block_category the category of block that was dragged
   */
  workflowSidePanelBlockDragged(block_type: BlockType, block_category: BlockCategory) {
    this.heap.track('workflow_side_panel_block_dragged', {
      block_type,
      block_category,
    })
  }

  /**
   * Tracks when the back button on the block details view of the side panel is clicked.
   *
   * @param view the side panel view that was opened when the back button was clicked
   */
  workflowSidePanelBackButtonClicked(
    view: 'block details' | 'replace block' | 'add next step',
  ) {
    this.heap.track('workflow_side_panel_back_button_clicked', {
      view,
    })
  }

  /**
   * Tracks when the replace button on the block details view of the side panel is clicked.
   *
   * @param block_type the type of block for which the replace button was clicked
   */
  workflowSidePanelReplaceBlockButtonClicked(block_type: BlockType) {
    this.heap.track('workflow_side_panel_replace_block_button_clicked', { block_type })
  }

  /**
   * Tracks when the description of a workflow in the workflow details view of the side panel is updated.
   *
   * @param is_new whether the description is new. If false, that means the description was edited
   */
  workflowSidePanelDescriptionUpdated(is_new: boolean) {
    this.heap.track('workflow_side_panel_description_updated', {
      is_new,
    })
  }

  /**
   * Tracks when the delete button in the block details view of the side panel is clicked.
   *
   * @param block_type the type of block for which the delete button was clicked
   */
  workflowSidePanelDeleteBlockButtonClicked(block_type: BlockType) {
    this.heap.track('workflow_side_panel_delete_block_button_clicked', { block_type })
  }

  /**
   * Tracks when one of the Next Steps in the block details side panel view is clicked.
   *
   * @param source_block_type the block that the side panel was opened for
   * @param target_branch_key the branch key of the clicked next step
   * @param target_block_type the type of the clicked next step. 'unset' if the user selects
   * the options to add a new step.
   */
  workflowSidePanelNextStepClicked(
    source_block_type: BlockType,
    target_branch_key: string,
    target_block_type: BlockType | 'unset',
  ) {
    this.heap.track('workflow_side_panel_next_step_clicked', {
      source_block_type,
      target_branch_key,
      target_block_type,
    })
  }

  /**
   * Tracks when a phone number is opted in to the call flow beta
   *
   * @param phone_number_id the ID of the phone number that was opted in
   */
  incomingCallWorkflowOptedIn(phone_number_id: string) {
    this.heap.track('incoming_call_workflow_opted_in', { phone_number_id })
  }

  /**
   * Tracks when all phone numbers are opted in to the call flow beta
   */
  incomingCallWorkflowAllNumbersOptedIn() {
    this.heap.track('incoming_call_workflow_all_numbers_opted_in')
  }

  /**
   * Tracks when a phone number is opted out of the call flow beta
   *
   * @param phone_number_id the ID of the phone number that was opted out
   */
  incomingCallWorkflowOptedOut(phone_number_id: string) {
    this.heap.track('incoming_call_workflow_opted_out', { phone_number_id })
  }

  /**
   * Tracks when one of the incoming call workflows is selected for viewing.
   *
   * @param source where the selection was made
   * @param workflow_type the type of workflow that was viewed
   */
  incomingCallWorkflowViewed(
    source: 'header' | 'side panel',
    workflow_type: WorkflowType,
  ) {
    this.heap.track('incoming_call_workflow_viewed', {
      source,
      workflow_type,
    })
  }

  /**
   * Tracks when one of the incoming call workflows is enabled.
   *
   * @param workflow_type the type of workflow that was enabled
   */
  incomingCallWorkflowEnabled(workflow_type: WorkflowType) {
    this.heap.track('incoming_call_workflow_enabled', {
      workflow_type,
    })
  }

  /**
   * Tracks when the “Go back” button in the inactive workflow banner is clicked.
   *
   * @param workflow_type the type of workflow that was being viewed when the button was clicked
   */
  incomingCallWorkflowGoBackToActiveButtonClicked(workflow_type: WorkflowType) {
    this.heap.track('incoming_call_workflow_go_back_to_active_button_clicked', {
      workflow_type,
    })
  }

  /**
   * Tracks when the link in the callout in the Business Hours side panel view is clicked.
   */
  incomingCallWorkflowBusinessHoursLinkClicked() {
    this.heap.track('incoming_call_workflow_business_hours_link_clicked')
  }
}
