/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import type { ReactNode } from 'react'
import { useSyncExternalStore, useEffect, useContext, createContext } from 'react'

import useStore from '@src/lib/hooks/useStore'

export type Sensor = { type: 'pointer' } | { type: 'keyboard'; key: KeyboardEvent['key'] }

type Subscriber = () => void

export class SensorStore {
  private state: Sensor = { type: 'pointer' }
  private subscribers: Set<Subscriber> = new Set()

  constructor() {
    this.subscribe = this.subscribe.bind(this)
    this.getState = this.getState.bind(this)
  }

  setPointer() {
    this.state = { type: 'pointer' }
    this.notify()
  }

  setKeyboard(key: KeyboardEvent['key']) {
    this.state = { type: 'keyboard', key }
    this.notify()
  }

  getState(): Sensor {
    return this.state
  }

  subscribe(subscriber: Subscriber) {
    this.subscribers.add(subscriber)
    return () => this.subscribers.delete(subscriber)
  }

  notify() {
    for (const subscriber of this.subscribers) {
      subscriber()
    }
  }

  tearDown() {
    this.subscribers.clear()
  }
}

const SensorContext = createContext<SensorStore>(new SensorStore())

/**
 * Gives the sensor in use, either `pointer` or `keyboard` and the `key`
 * it was triggered with.
 */
export const useSensor = () => {
  const context = useContext(SensorContext)
  return useSyncExternalStore(context.subscribe, context.getState)
}

interface SensorProviderProps {
  children: ReactNode
}

export default function SensorProvider({ children }: SensorProviderProps) {
  const store = useStore(() => new SensorStore(), [])

  useEffect(() => {
    const handleMouseMove = () => {
      store.setPointer()
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      store.setKeyboard(event.key)
      window.addEventListener('mousemove', handleMouseMove, { capture: true, once: true })
    }

    window.addEventListener('keydown', handleKeyDown, { capture: true })

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [store])

  return <SensorContext.Provider value={store}>{children}</SensorContext.Provider>
}
