import dayjs from 'dayjs'
import { makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'

import type AppStore from '@src/app/AppStore'
import config from '@src/config'
import { DisposeBag } from '@src/lib/dispose'
import makePersistable from '@src/service/storage/makePersistable'

const SIFT_SCRIPT_SRC = 'https://cdn.sift.com/s.js'

type Property = '_setAccount' | '_setUserId' | '_setSessionId'
type Session = {
  id: string
  expiresAt: number
  client: 'web'
}

declare global {
  interface Window {
    _sift: any
  }
}

export class Sift {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  private _sift: ([Property, string] | [string])[] = (window._sift = window._sift ?? [])
  private sessionId = ''
  private expirationTimeout: NodeJS.Timeout | undefined
  private disposeBag = new DisposeBag()

  constructor(private readonly root: AppStore) {
    makeAutoObservable<this, '_sift'>(this, {
      _sift: false,
    })

    makePersistable(this, 'Sift', {
      sessionId: root.service.storage.sync(),
    })

    this.setInitialState()
    this.trackPageView()
    this.appendSiftScript()

    const setUserIdDisposer = reaction(
      () => root.service.user.current?.id,
      (value) => {
        if (!value) {
          return
        }
        this.set('_setUserId', value ?? '')
      },
      { fireImmediately: true },
    )

    const trackNavigationDisposer = reaction(
      () => root.history.location,
      () => {
        this.trackPageView()
      },
    )

    this.disposeBag.add(setUserIdDisposer, trackNavigationDisposer)
  }

  makeSession(): Session {
    return {
      id: nanoid(),
      expiresAt: dayjs().add(24, 'hours').valueOf(),
      client: 'web',
    }
  }

  encodeSession(session: Session) {
    return btoa(JSON.stringify(session))
  }

  decodeSession(sessionId: string): Session | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return JSON.parse(atob(sessionId))
  }

  createSession() {
    clearTimeout(this.expirationTimeout)
    const session = this.makeSession()
    this.sessionId = this.encodeSession(session)
    this.expirationTimeout = setTimeout(() => {
      this.resetSessionIfNeeded()
    }, session.expiresAt - Date.now())
  }

  resetSessionIfNeeded() {
    const session = this.decodeSession(this.sessionId)
    if (!session || Date.now() >= session.expiresAt) {
      this.createSession()
    }
  }

  setInitialState() {
    this.set('_setAccount', config.SIFT_BEACON_KEY)

    if (!this.sessionId) {
      this.createSession()
    } else {
      this.resetSessionIfNeeded()
    }

    this.set('_setSessionId', this.sessionId)
  }

  set(property: Property, value: string) {
    this._sift.push([property, value])
  }

  trackPageView() {
    this._sift.push(['_trackPageview'])
  }

  appendSiftScript() {
    const doesExist = document.querySelector('[data-sift]')
    if (doesExist) {
      return
    }
    const script = document.createElement('script')
    script.async = true
    script.src = SIFT_SCRIPT_SRC
    script.setAttribute('data-sift', '')
    document.head.appendChild(script)
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
