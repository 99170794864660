import { useTooltipTrigger, useTooltip } from '@react-aria/tooltip'
import { mergeProps, mergeRefs } from '@react-aria/utils'
import { useTooltipTriggerState } from '@react-stately/tooltip'
import type { TooltipTriggerProps } from '@react-types/tooltip'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { CSSProperties, RefObject } from 'react'
import { cloneElement, Children, useRef } from 'react'
import type { TransitionStatus } from 'react-transition-group'
import { Transition } from 'react-transition-group'

import Popover, { type PopoverProps } from '@ui/Popover'
import { useSequentialHoverTooltip } from '@ui/TooltipProvider'
import { useTheme, themeClassNames, ThemeProvider, type ThemeKey } from '@ui/theme'

import * as styles from './Tooltip.css'
import TooltipContent from './TooltipContent'

type PopoverPlacement = NonNullable<PopoverProps['placement']>
export type TooltipPlacement = Extract<
  PopoverPlacement,
  | 'top'
  | 'top left'
  | 'top right'
  | 'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'left'
  | 'right'
>
export type TooltipColorScheme = 'default' | 'call'
export type TooltipAlign = 'start' | 'center' | 'end'

export interface TooltipProps {
  /**
   * A single child that will act as the trigger for the tooltip.
   *
   * @example
   * ```jsx
   * <Tooltip title="This is a tooltip">
   *   <button>Trigger</button>
   * </Tooltip>
   * ```
   */
  children: React.ReactChild

  /**
   * The text to display in the tooltip.
   */
  title: string

  /**
   * Secondary text to display in the tooltip.
   */
  body?: string

  /**
   * Icon to display next to the title in the tooltip.
   */
  icon?: React.ReactNode

  /**
   * Property to prevent the tooltip from appearing.
   */
  disabled?: boolean

  /**
   * Whether the tooltip should be hidden.
   *
   * This differs from the `disabled` prop because it allows the tooltip to
   * become immediately visible again once the prop changes back to `false`,
   * unlike `disabled` which requires a mouseOut and mouseIn on the trigger
   * element to re-open the tooltip.
   */
  isHidden?: boolean

  /**
   * Key combination to display in the tooltip.
   *
   * @example
   * 'Meta+Shift+X'
   */
  shortcut?: string

  /**
   * Classname to apply to the tooltip.
   */
  className?: string

  /**
   * Tooltip placement.
   *
   * @default 'bottom'
   */
  placement?: TooltipPlacement

  /**
   * Tooltip content alignment.
   *
   * If not provided, the content alignment will depend on the `placement` prop.
   */
  align?: TooltipAlign

  /**
   * The delay time for the tooltip to show up. [See guidelines](https://spectrum.adobe.com/page/tooltip/#Immediate-or-delayed-appearance).
   * @default 400
   */
  delay?: number

  /**
   * By default, opens for both focus and hover. Can be made to open only for focus.
   *
   * @see https://react-spectrum.adobe.com/react-aria/useTooltipTrigger.html#api
   */
  trigger?: 'focus'

  /**
   * Whether the overlay is open by default (controlled).
   */
  open?: boolean

  /**
   * Whether the overlay is open by default (uncontrolled).
   */
  defaultOpen?: boolean

  /**
   * Handler that is called when the overlay's open state changes.
   */
  onOpenChange?: (open: boolean) => void

  /**
   * Enforce themeKey
   */
  themeKey?: ThemeKey

  /**
   * Color scheme of the tooltip.
   *
   * If `colorScheme` is set to `call`, `themeKey` value will be ignored and `dark` will be used instead.
   *
   * @default 'default'
   */
  colorScheme?: TooltipColorScheme

  /**
   * The additional offset applied along the main axis between the element and its
   * anchor element. Use sparingly.
   *
   * @default 6
   */
  offset?: number

  /**
   * The additional offset applied along the cross axis between the element and its
   * anchor element. Use sparingly.
   *
   * @default 0
   */
  crossOffset?: number

  /**
   * A ref to the element which the popover positions itself with respect to.
   */
  targetRef?: React.RefObject<Element>

  /**
   * Whether to allow drag handling for the trigger element.
   *
   * By default, react-aria applies `user-select: none` to the trigger element
   * on press which prevents dragging.
   */
  allowDragging?: boolean

  /**
   * Whether to allow text selection within the trigger element.
   */
  allowTextSelection?: boolean
}

/**
 * The animation duration for the first time a tooltip shows
 */
const INITIAL_ANIMATION_DURATION = 200

/**
 * The animation duration for sequential hovers among tooltiped elements
 */
const SEQUENTIAL_ANIMATION_DURATION = 100

/**
 * Time it takes for the tooltip to start transitioning in.
 * This means that until this 400ms have passed the tooltip
 * will not be mounted.
 */
const INITIAL_DELAY_DURATION = 400

const transitionStyles: Record<TransitionStatus, CSSProperties> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: {},
}

const Tooltip = ({
  children,
  title,
  body,
  icon,
  className,
  disabled,
  shortcut,
  delay = INITIAL_DELAY_DURATION,
  trigger,
  placement = 'bottom',
  align,
  open,
  defaultOpen,
  onOpenChange,
  themeKey: themeKeyProp,
  colorScheme = 'default',
  offset = 6,
  crossOffset,
  targetRef,
  allowDragging,
  isHidden,
  allowTextSelection,
}: TooltipProps) => {
  const themeKey = colorScheme === 'call' ? 'dark' : themeKeyProp
  const enforceTheme = !!themeKey
  const theme = useTheme(themeKey)
  const child = Children.only(children)

  const triggerRef = useRef<HTMLElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const tooltipTriggerProps: TooltipTriggerProps = {
    delay,
    isDisabled: disabled,
    trigger,
    isOpen: open,
    defaultOpen,
    onOpenChange,
  }
  const state = useTooltipTriggerState(tooltipTriggerProps)
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    tooltipTriggerProps,
    { ...state, close: () => state.close(true) },
    triggerRef,
  )
  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  delete triggerProps.onClick

  if (allowDragging || allowTextSelection) {
    delete triggerProps.onPointerDown
    if (allowTextSelection) {
      delete triggerProps.onMouseDown
    }
  }

  let triggerElement: React.ReactElement

  if (isReactText(child)) {
    triggerElement = (
      <span {...triggerProps} ref={triggerRef}>
        {child}
      </span>
    )
  } else {
    const mergedRef = hasRef(child) ? mergeRefs(child.ref, triggerRef) : triggerRef
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const mergedProps = mergeProps(triggerProps, child.props, { ref: mergedRef })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    triggerElement = cloneElement(child, mergedProps)
  }

  const tooltip = useTooltip({}, state)
  const { isSequentialHover, setCurrentlyOpenedTooltip, unsetCurrentlyOpenedTooltip } =
    useSequentialHoverTooltip(state.isOpen)

  if (disabled) {
    return <>{children}</>
  }

  const alignment: TooltipAlign = (() => {
    if (align) {
      return align
    }

    const isCentered = !shortcut && (placement === 'top' || placement === 'bottom')
    return isCentered ? 'center' : 'start'
  })()

  return (
    <>
      {triggerElement}
      <Transition
        in={state.isOpen && !isHidden}
        mountOnEnter
        unmountOnExit
        timeout={isSequentialHover ? SEQUENTIAL_ANIMATION_DURATION : delay}
        onEntering={setCurrentlyOpenedTooltip}
        onExit={unsetCurrentlyOpenedTooltip}
      >
        {(state) => (
          <ThemeProvider
            themeKey={
              enforceTheme
                ? theme.themeKey
                : theme.match({
                    light: 'dark',
                    dark: 'light',
                  })
            }
          >
            <Popover
              targetRef={targetRef ?? triggerRef}
              className={
                enforceTheme
                  ? theme.themeClassName
                  : theme.match({
                      light: themeClassNames.dark,
                      dark: themeClassNames.light,
                    })
              }
              placement={placement}
              offset={offset}
              crossOffset={crossOffset}
              withUnderlay={false}
            >
              <div
                className={cx(
                  styles.tooltip({ isSequentialHover, colorScheme }),
                  className,
                )}
                {...mergeProps({}, tooltipProps, tooltip.tooltipProps)}
                ref={tooltipRef}
                style={{
                  ...transitionStyles[state],
                  ...assignInlineVars({
                    [styles.initialAnimationDurationVar]: `${INITIAL_ANIMATION_DURATION}ms`,
                    [styles.sequentialAnimationDurationVar]: `${SEQUENTIAL_ANIMATION_DURATION}ms`,
                  }),
                }}
              >
                <TooltipContent
                  title={title}
                  body={body}
                  icon={icon}
                  shortcut={shortcut}
                  align={alignment}
                />
              </div>
            </Popover>
          </ThemeProvider>
        )}
      </Transition>
    </>
  )
}

export default Tooltip

function isReactText(node: React.ReactChild): node is React.ReactText {
  return typeof node === 'string' || typeof node === 'number'
}

function hasRef(
  element: React.ReactElement,
): element is React.ReactElement & { ref: RefObject<unknown> } {
  return !!element['ref']
}
