import { mergeRefs } from '@react-aria/utils'
import { observer } from 'mobx-react-lite'
import type { CSSProperties } from 'react'
import { forwardRef, useCallback, useRef } from 'react'
import { fromEvent } from 'rxjs'

import useDebug from '@src/lib/hooks/useDebug'
import useObservable from '@src/lib/hooks/useObservable'
import useReaction from '@src/lib/hooks/useReaction'
import useSize from '@src/lib/hooks/useSize'

import * as styles from './ScrollContainer.css'
import type { ScrollViewStore } from './store'

export interface ScrollContainerProps {
  store: ScrollViewStore
  className?: string
  withShadows?: boolean
  overscrollBehavior?: CSSProperties['overscrollBehavior']
  children: React.ReactNode
}

const ScrollContainer = forwardRef<HTMLDivElement, ScrollContainerProps>(
  ({ store, className, withShadows = false, children, overscrollBehavior }, outerRef) => {
    const debug = useDebug(`op:scrollview:@${store.name}`)
    const ref = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)

    useObservable(
      () => {
        if (!ref.current) {
          return
        }
        return fromEvent(ref.current, 'scroll', { passive: true })
      },
      () => {
        if (!ref.current) {
          return
        }
        store.onScroll(ref.current.scrollLeft, ref.current.scrollTop)
      },
      [store],
    )

    useReaction(
      () => store.scrollTo,
      ({ top }) => {
        debug(`useReaction scrollOffset: ${top}`)
        if (!ref.current) {
          return
        }
        ref.current.scrollTo({ top, left: 0 })
      },
      { fireImmediately: true },
      [store],
    )

    useSize({
      ref: contentRef,
      onResize: useCallback(
        ({ scrollWidth: width, scrollHeight: height }) => {
          store.setContentSize({ width, height })
        },
        [store],
      ),
      useMutationObserver: true,
    })

    useSize({
      ref,
      onResize: useCallback(
        ({ width, height }) => {
          store.setSize({ width, height })
        },
        [store],
      ),
      useMutationObserver: true,
    })

    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    const mergedRefs = mergeRefs(outerRef, ref)

    return (
      <div
        ref={mergedRefs}
        className={styles.root({ withShadows })}
        style={{ overscrollBehavior }}
      >
        <div ref={contentRef} className={className}>
          {children}
        </div>
      </div>
    )
  },
)

export default observer(ScrollContainer)
