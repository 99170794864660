import { action, makeObservable } from 'mobx'

import { isArrayOfStrings } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import { runInBatches } from '@src/lib/util'
import type { Model } from '@src/service/model/base'
import type Repository from '@src/service/worker/repository/base'

import Collection from './Collection'

export interface PersistedCollectionOptions<T extends Model, Repo extends Repository<T>> {
  readonly table: Repo
  readonly classConstructor: (json: any) => T | null
  readonly idKey?: string
  filter?: (item: T) => boolean
  compare?: (a: T, b: T) => number
}

export default class PersistedCollection<
  T extends Model,
  Repo extends Repository<T>,
> extends Collection<T> {
  private lazyIds = new Set<string>()
  private lazyTimeout: any

  protected table: Repo
  protected classConstructor: (json: any) => T | null

  constructor({
    table,
    classConstructor,
    idKey = 'id',
    filter,
    compare,
  }: PersistedCollectionOptions<T, Repo>) {
    super({ idKey, bindElements: true, filter, compare })
    this.table = table
    this.classConstructor = classConstructor
    makeObservable<this, 'lazyIds'>(this, {
      load: action,
      lazyIds: false,
    })
  }

  override get(
    id: string | null,
    opts: { skipStorage: boolean } = { skipStorage: false },
  ): T | null {
    if (!id) {
      return null
    }
    const element = this.elements.get(id) ?? null
    if (!element && !opts.skipStorage) {
      this.getLazily(id)
    }
    return element
  }

  override has(id: string): boolean {
    if (!id) {
      return false
    }
    const element = super.has(id)
    if (!element) {
      this.getLazily(id)
    }
    return element
  }

  isInMemory(id: string) {
    return super.has(id)
  }

  override put(obj: T) {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!, UXP-3744 - Fix Promise-related ESLint issues */
    this.table.put(obj.serialize())
    super.put(obj)
  }

  override putBulk(objs: readonly T[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    const data = objs.map((o) => o.serialize())
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!, UXP-3744 - Fix Promise-related ESLint issues */
    this.table.putBulk(data)
    super.putBulk(objs)
  }

  override delete(o: string | T) {
    if (typeof o === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.table.delete(o)
    } else {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!, UXP-3744 - Fix Promise-related ESLint issues */
      this.table.delete(o[this.idKey] as any)
    }
    super.delete(o)
  }

  override deleteBulk(o: string[] | readonly T[]) {
    if (isArrayOfStrings(o)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.table.deleteBulk(o)
    } else {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!, UXP-3744 - Fix Promise-related ESLint issues */
      this.table.deleteBulk(o.map((o) => o[this.idKey]))
    }
    super.deleteBulk(o)
  }

  performQuery<R>(query: (repo: Repo) => Promise<R>): Promise<R> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return query(this.table).then((data) => {
      if (Array.isArray(data)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        return this.load(data, { skipPersisting: true }) as any
      } else {
        return this.load(data, { skipPersisting: true }).then((results) => results[0])
      }
    })
  }

  deleteQuery(query: (repo: Repo) => Promise<T[]>): Promise<void> {
    return query(this.table).then((data) => this.deleteBulk(data))
  }

  /**
   * Updates the memory objects or creates new ones based on the passed in data.
   * If the save options is true, saves objects to persistent DB.
   * Note: Consider automating the save step to store back to DB when the object has
   * changed compared to the memory version.
   */
  load = async (
    data: any,
    opts: { skipPersisting?: boolean; deleteOthers?: boolean } = {},
  ): Promise<T[]> => {
    const { skipPersisting, deleteOthers } = opts

    if (!data) {
      return []
    }

    if (!Array.isArray(data)) {
      data = [data]
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    const objects = await runInBatches(data, 1000, (batch) =>
      batch
        .map((json: any) => {
          if (!json) {
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          const id = json[this.idKey]
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          const object = this.elements.get(id)

          if (this.shouldSkipItemIfStale(json, object)) {
            return
          }

          if (object) {
            return object.deserialize(json)
          } else {
            return this.classConstructor(json)?.deserialize(json)
          }
        })
        .filter(isNonNull),
    )

    /**
     * Turns json into model instances. The conversion is done in batches to
     * not clog up the runtime with massive data sets.
     */

    super.putBulk(objects)

    if (!skipPersisting) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      const objs = objects.map((o) => o.serialize())
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-floating-promises -- FIXME: Fix this ESLint violation!, UXP-3744 - Fix Promise-related ESLint issues */
      this.table.putBulk(objs)
    }

    if (deleteOthers) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      const keys: string[] = objects.map((o) => o[this.idKey] as any)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.table
        .getOthers(keys)
        .then((others) => this.deleteBulk(others.map((o) => o.id)))
    }

    return objects
  }

  private getLazily(id: string) {
    this.lazyIds.add(id)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    clearTimeout(this.lazyTimeout)
    this.lazyTimeout = setTimeout(() => {
      const ids = [...this.lazyIds]
      this.lazyIds.clear()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.table.getBulk(ids).then((data) => this.load(data, { skipPersisting: true }))
    }, 0)
  }
}
