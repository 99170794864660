import { makeAutoObservable } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'
import { TagModel } from '@src/service/model/tags'
import type { CodableTag } from '@src/service/model/tags/TagModel'
import type { LabelDelete, LabelUpdate } from '@src/service/transport/websocket'
import type { TagRepository } from '@src/service/worker/repository/TagRepository'
import { TAG_TABLE_NAME } from '@src/service/worker/repository/TagRepository'

import type Service from '..'

export default class TagStore {
  readonly collection: PersistedCollection<TagModel, TagRepository>

  constructor(private root: Service) {
    this.handleWebsocket()
    this.collection = new PersistedCollection({
      table: root.storage.table(TAG_TABLE_NAME),
      classConstructor: (json: CodableTag) => new TagModel(json),
    })

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'label-update':
          this.handleLabelUpdate(msg)
          break
        case 'label-delete':
          this.handleLabelDelete(msg)
          break
      }
    })
  }

  private handleLabelUpdate({ label }: LabelUpdate) {
    const tag = this.collection.get(label.id, { skipStorage: true })
    if (!tag) {
      void this.collection.load([label])
    } else {
      tag.deserialize(label)
      this.collection.put(tag)
    }
  }

  private handleLabelDelete({ id }: LabelDelete) {
    this.collection.delete(id)
  }

  async getByPhoneNumberId(phoneNumberId: string) {
    const tags =
      await this.root.transport.communication.tags.listByPhoneNumberId(phoneNumberId)
    const tagModels = await this.collection.load(tags)
    return tagModels
  }

  async create(
    tag: Pick<CodableTag, 'name' | 'description' | 'emoji' | 'orgId' | 'phoneNumberId'>,
  ) {
    const response = await this.root.transport.communication.tags.create(tag)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection.load(response)
  }

  async update(tag: Pick<CodableTag, 'id' | 'name' | 'description' | 'emoji'>) {
    const response = await this.root.transport.communication.tags.update(tag)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection.load(response)
  }

  async toggle(tagId: string, isEnabled: boolean) {
    return this.root.transport.communication.tags.toggle(tagId, isEnabled)
  }

  async deleteTagFromActivity({
    tagId,
    activityId,
  }: {
    tagId: string
    activityId: string
  }) {
    const tag = this.collection.get(tagId)
    const activity = this.root.activity.get(activityId)

    if (!tag || !activity) {
      return
    }

    const liteTagIndexToRemove = activity.tags.findIndex(
      (liteTag) => liteTag.id === tag.id,
    )

    if (liteTagIndexToRemove === -1) {
      return
    }

    const liteTag = activity.tags[liteTagIndexToRemove]
    activity.tags.splice(liteTagIndexToRemove, 1)

    try {
      await this.root.transport.communication.tags.deleteTagFromActivity({
        activityId,
        tagId,
      })
    } catch (error) {
      // @ts-expect-error unchecked index access
      activity.tags.splice(liteTagIndexToRemove, 0, liteTag)
      throw error
    }
  }

  async delete(tagId: string) {
    const tag = this.collection.get(tagId)

    if (!tag) {
      return
    }

    const tagIndexToRemove = this.collection.list.findIndex((tag) => tag.id === tagId)

    if (tagIndexToRemove === -1) {
      return
    }

    this.collection.delete(tag)
    try {
      await this.root.transport.communication.tags.delete(tagId)
    } catch (error) {
      this.collection.put(tag)
      throw error
    }
  }
}
