import cx from 'classnames'
import type { MouseEvent } from 'react'
import { forwardRef } from 'react'

import { LoadingIndicator } from '@ui/LoadingIndicator'

import * as styles from './Button.css'

export type ButtonVariant = 'contained' | 'outlined' | 'ghost' | 'text'
export type ButtonColor =
  | 'primary'
  | 'danger'
  | 'secondaryGreen'
  | 'textPrimary'
  | 'textSecondary'
  | 'neutral'
  | 'blue'
type ButtonHeight = 48 | 45 | 44 | 40 | 36 | 35 | 34 | 32 | 30 | 24 | 20 | 12 | 'unset'

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  disabled?: boolean
  loading?: boolean
  align?: 'start' | 'center' | 'end'
  variant?: ButtonVariant
  color?: ButtonColor
  height?: ButtonHeight
  fullWidth?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  /**
   * @deprecated Use Typography, a className, or the style prop instead
   */
  fontWeight?: 'regular'
  contentClassName?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function (
  {
    variant = 'contained',
    color = 'primary',
    align = 'center',
    loading = false,
    disabled,
    height = 40,
    fullWidth = false,
    children,
    startIcon,
    endIcon,
    className,
    fontWeight,
    contentClassName,
    style,
    onClick,
    ...props
  },
  ref,
) {
  const isDisabled = disabled || loading

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) {
      event.preventDefault()
      return
    }

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <button
      ref={ref}
      aria-disabled={isDisabled}
      className={cx(
        styles.root({
          variant,
          color,
          fullWidth: String(fullWidth),
          regularFontWeight: String(fontWeight === 'regular'),
        }),
        className,
      )}
      style={{ height, ...style }}
      onClick={handleClick}
      {...props}
    >
      <div
        className={cx(
          styles.content({ loading: String(loading), align }),
          contentClassName,
        )}
      >
        {startIcon && <div className={styles.startIcon}>{startIcon}</div>} {children}
        {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
      </div>
      {loading && (
        <LoadingIndicator className={styles.loadingIndicator} variant="dotted" />
      )}
    </button>
  )
})

export default Button
