import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type SnippetsService from '@src/service/snippet-store'

import type { Model } from '.'

export interface CodableSnippet {
  id: string
  userId: string | null
  name: string
  text: string
  sharedWith: string[]
  createdAt: number
  updatedAt: number
}

class SnippetModel implements Model, CodableSnippet {
  id: string = objectId()
  userId: string | null = null
  name = ''
  text = ''
  sharedWith: string[] = []
  createdAt = Date.now()
  updatedAt = Date.now()

  // Local
  isNew = false

  constructor(
    private snippetStore: SnippetsService,
    attrs: Partial<CodableSnippet & { isNew: boolean }> = {},
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  update = (attributes: Partial<SnippetModel>) => {
    Object.assign(this, attributes)
    this.save()
  }

  save = () => {
    this.isNew = false
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.snippetStore.save(this)
  }

  delete = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.snippetStore.delete(this)
  }

  deserialize = (json: Partial<CodableSnippet>) => {
    Object.assign(this, json)

    if (json.updatedAt) {
      this.updatedAt = parseDate(json.updatedAt) ?? this.updatedAt
    }

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt) ?? this.createdAt
    }

    return this
  }

  serialize = (): CodableSnippet => {
    return {
      id: this.id,
      userId: this.userId,
      name: this.name,
      text: this.text,
      sharedWith: toJS(this.sharedWith),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}

export default SnippetModel
