import type { PopoverOrigin } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { useAutocomplete, type FilterOptionsState } from '@material-ui/lab'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import {
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
  type ForwardRefExoticComponent,
  type ReactNode,
} from 'react'

import PopoverMenu from '@src/component/popover-menu'
import { ScrollView } from '@src/component/scrollview'
import useInputState from '@src/lib/hooks/useInputState'
import Menu from '@ui/Menu'
import TextField from '@ui/TextField'
import Typography from '@ui/Typography'

import * as styles from './SelectSearch.css'
import * as defaultOptionStyles from './SelectSearchDefaultOption.css'

export type SelectSearchOptionValue = {
  id?: string
  name: string
  value: string
  __isTyped?: boolean
}
export type RenderOptionProps<T extends SelectSearchOptionValue> = {
  option: T
  index: number
  // eslint-disable-next-line @typescript-eslint/ban-types -- FIXME: Fix this ESLint violation!
  rootOptionProps: {}
}

export interface TriggerProps<
  T extends SelectSearchOptionValue = SelectSearchOptionValue,
> {
  onClick: () => void
  children: ReactNode
  isOpen: boolean
  selectedValue: T | T[]
  disabled: boolean
}

export type TriggerElement = HTMLButtonElement & HTMLDivElement

export type SelectSearchFooterProps = { onClose?: () => void }

export interface SelectSearchProps<T extends SelectSearchOptionValue> {
  options: T[]
  onChange: (value: T | T[]) => void
  value: NonNullable<T> | NonNullable<T>[]
  open?: boolean
  onClose?: () => void
  rootClassName?: string
  dropdownProps?: Partial<{
    placeholder: string
    footer?: FunctionComponent<SelectSearchFooterProps>
    header?: FunctionComponent<{ onClose?: () => void }>
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    className?: string
  }>
  renderOption?: FunctionComponent<RenderOptionProps<T>>
  getOptionDisabled?: (option: T | T[]) => boolean
  getOptionName?: (option: NonNullable<T> | NonNullable<T>[]) => string
  multiple?: boolean

  renderTrigger?:
    | ForwardRefExoticComponent<TriggerProps & React.RefAttributes<HTMLElement>>
    | ForwardRefExoticComponent<TriggerProps & React.RefAttributes<HTMLButtonElement>>
  /**
   * A filter function that determines the options that are eligible.
   *
   * @param {T[]} options The options to render.
   * @param {object} state The state of the component.
   * @returns {T[]}
   */
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[]

  /**
   * Enabling this allows the user to select a custom option with the value of the typed text in the search input.
   *
   * Should be used with `getTypedOption`
   */
  addTypedOption?: boolean

  /**
   * Allows the component to retrieve a compatible typed option
   *
   * Should be used with `addTypedOption`
   */
  getTypedOption?: (value: string) => T

  /**
   * Hides the search input
   */
  hideSearch?: boolean

  /**
   * Disables the component
   */
  disabled?: boolean
}

const SelectSearch = <T extends SelectSearchOptionValue>({
  options,
  onChange,
  value: controlledValue,
  renderOption,
  getOptionName,
  open = false,
  multiple = false,
  disabled = false,
  onClose,
  rootClassName,
  dropdownProps = {},
  renderTrigger,
  filterOptions,
  addTypedOption,
  getTypedOption,
  hideSearch,
  getOptionDisabled,
}: SelectSearchProps<T>) => {
  const [isOpen, setOpen] = useState(open)
  const [text, setText] = useInputState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const rootRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<TriggerElement>(null)

  if (addTypedOption && getTypedOption && text.trim()) {
    const typedOption = getTypedOption(text)
    typedOption.__isTyped = true

    const hasTypedOption = options.some(
      (option) =>
        option.value.toLowerCase() === text.toLowerCase() ||
        option.name.toLowerCase() === text.toLowerCase(),
    )

    if (!hasTypedOption) {
      options = [...options, typedOption]
    }
  }

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  const handleClose = () => {
    setOpen(false)
    setText('')
    if (triggerRef.current) {
      triggerRef.current.focus()
    }
    onClose?.()
  }

  useEffect(() => {
    setOpen(open)
  }, [open])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' || event.code === 'Tab') {
        event.preventDefault()
        handleClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown, { capture: true })
    return () => {
      window.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
  }, [isOpen, handleClose])

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
  } = useAutocomplete<T, typeof multiple>({
    id: 'select-search',
    options: options,
    multiple,
    getOptionLabel: (option) => option.name,
    getOptionDisabled,
    getOptionSelected: (option, value) => {
      if ('id' in option && 'id' in value) {
        return option.id === value.id
      }

      return option.name === value.name && option.value === value.value
    },
    open: isOpen,
    value: controlledValue,
    inputValue: text,
    onChange: (_, value, reason) => {
      if (reason === 'select-option' || (multiple && reason === 'remove-option')) {
        onChange(value as T | T[])
        if (!multiple) {
          handleClose()
          setText('')
        }
      }
    },
    filterOptions,
  })

  const TriggerComponent = () => {
    const onClick = () => setOpen(true)
    const text = (
      <Typography variant="footnote" nowrap>
        {getOptionName
          ? getOptionName(value as NonNullable<T>)
          : value
          ? (value as SelectSearchOptionValue).name
          : ''}
      </Typography>
    )

    if (renderTrigger) {
      const Trigger = renderTrigger
      return (
        <Trigger
          selectedValue={value as T}
          isOpen={isOpen}
          onClick={onClick}
          ref={triggerRef}
          disabled={disabled}
        >
          {text}
        </Trigger>
      )
    }

    return (
      <div className={cx(styles.displayOptionRoot)} onClick={onClick} ref={triggerRef}>
        {text}
        {isOpen ? (
          <ArrowDropUpIcon className={styles.icon} />
        ) : (
          <ArrowDropDownIcon className={styles.icon} />
        )}
      </div>
    )
  }

  const OptionComponent = renderOption ? renderOption : DefaultOption

  const HeaderPopover = dropdownProps?.header
  const FooterPopover = dropdownProps?.footer

  return (
    <div {...getRootProps()} ref={rootRef} className={rootClassName}>
      <TriggerComponent />
      <input
        {...getInputProps()}
        style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }}
      />
      <PopoverMenu
        open={isOpen}
        onClose={handleClose}
        anchorEl={rootRef.current}
        anchorOrigin={
          dropdownProps?.anchorOrigin ?? { vertical: 'bottom', horizontal: 'left' }
        }
        transformOrigin={
          dropdownProps?.transformOrigin ?? { vertical: 'top', horizontal: 'left' }
        }
        style={{ margin: '5px 0' }}
        className={cx(styles.popOverMenu)}
        classes={{ paper: dropdownProps.className }}
      >
        <>
          {hideSearch ? null : (
            <>
              <TextField
                textInputProps={{
                  className: styles.searchInput,
                }}
                value={text}
                onChange={setText}
                autoFocus
                ref={inputRef}
                placeholder={dropdownProps?.placeholder ?? 'Search for items...'}
              />
              <Menu.Separator />
            </>
          )}
          {HeaderPopover && <HeaderPopover onClose={handleClose} />}
          {groupedOptions.length > 0 ? (
            <>
              <ScrollView>
                <ul
                  className={cx(styles.listbox, {
                    [styles.listBoxWithAfterSeparator]: !hideSearch,
                  })}
                  {...getListboxProps()}
                >
                  {groupedOptions.map((option, index) => {
                    return (
                      <OptionComponent
                        key={index}
                        option={option}
                        index={index}
                        rootOptionProps={getOptionProps({ option, index })}
                      />
                    )
                  })}
                </ul>
              </ScrollView>
              {FooterPopover && <FooterPopover onClose={handleClose} />}
            </>
          ) : (
            <Typography className={styles.noOptionsLabel} variant="footnote">
              No options
            </Typography>
          )}
        </>
      </PopoverMenu>
    </div>
  )
}

function DefaultOption<T extends SelectSearchOptionValue>({
  option,
  rootOptionProps,
}: RenderOptionProps<T>) {
  const styles = defaultOptionStyles
  return (
    <li {...rootOptionProps} className={styles.optionContainer}>
      {option.name}
    </li>
  )
}

export default observer(SelectSearch)
