import debounce from 'lodash/fp/debounce'
import { useCallback, useRef, useState } from 'react'

type Direction = 'x' | 'y' | 'xy'

/**
 * Observes the underlying dom element that the ref attaches to
 * and checks if it overflows in the axises specified.
 */
export default function useIsOverflowing<T extends HTMLElement>(
  direction: Direction = 'x',
) {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const checkX = direction.includes('x')
  const checkY = direction.includes('y')

  const checkOverflow: ResizeObserverCallback = useCallback(
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    debounce(100, (entries: ResizeObserverEntry[]) => {
      const element = entries[0]?.target as HTMLElement

      const hasHorizontalOverflow = checkX && element.offsetWidth < element.scrollWidth

      const hasVerticalOverflow = checkY && element.offsetHeight < element.scrollHeight

      setIsOverflowing(hasHorizontalOverflow || hasVerticalOverflow)
    }),
    [checkX, checkY],
  )

  const callbackRef = useCallback(
    (node: T) => {
      if (!node) {
        resizeObserverRef.current?.disconnect()
        resizeObserverRef.current = null
        return
      }

      resizeObserverRef.current = new ResizeObserver(checkOverflow)
      resizeObserverRef.current.observe(node)
    },
    [checkOverflow],
  )

  return { isOverflowing, ref: callbackRef }
}
