/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import classNames from 'classnames'
import type { CSSProperties, HTMLAttributes } from 'react'
import { forwardRef, useMemo } from 'react'

import assertNever from '@src/lib/assertNever'
import Tooltip from '@ui/Tooltip'
import { useTheme, theme } from '@ui/theme'

import * as styles from './Avatar.css'
import badgeMaskUrl from './assets/badge.svg'
import defaultDarkImageUrl from './assets/default-dark.svg'
import defaultLightImageUrl from './assets/default-light.svg'
import maskLargeImageUrl from './assets/mask-large.svg'
import maskMediumImageUrl from './assets/mask-medium.svg'
import maskSmallImageUrl from './assets/mask-small.svg'
import maskTinyImageUrl from './assets/mask-tiny.svg'
import defaultWorkspaceDarkImageUrl from './assets/workspace-default-dark.svg'
import defaultWorkspaceLightImageUrl from './assets/workspace-default-light.svg'

// Assets
const defaultDarkUrl = defaultDarkImageUrl
const defaultLightUrl = defaultLightImageUrl
const defaultWorkspaceDarkUrl = defaultWorkspaceDarkImageUrl
const defaultWorkspaceLightUrl = defaultWorkspaceLightImageUrl

const backgroundColors = [
  `rgb(${theme.palette.contact.indigo.default})`,
  `rgb(${theme.palette.contact.leaf.default})`,
  `rgb(${theme.palette.contact.sky.default})`,
  `rgb(${theme.palette.contact.watermelon.default})`,
  `rgb(${theme.palette.contact.salmon.default})`,
  `rgb(${theme.palette.contact.tangerine.default})`,
  `rgb(${theme.palette.contact.cherokee.default})`,
  `rgb(${theme.palette.contact.turquoise.default})`,
  `rgb(${theme.palette.contact.mauve.default})`,
  `rgb(${theme.palette.contact.stone.default})`,
]

const mask = {
  tiny: maskTinyImageUrl,
  small: maskSmallImageUrl,
  medium: maskMediumImageUrl,
  large: maskLargeImageUrl,
}

export const avatarSizes = [
  14, 16, 18, 20, 22, 24, 26, 30, 34, 36, 40, 48, 56, 80, 96,
] as const

export type AvatarSize = (typeof avatarSizes)[number]

export interface AvatarBaseProps {
  style?: CSSProperties
  className?: string
  circleClassName?: string
  url?: string
  retinaUrl?: string
  /**
   * If defined, displays the component as the supplied emoji character, without any gradient background. It is used to display inboxes
   */
  symbol?: string
  initials?: string
  colorId?: string

  /**
   * The size of the avatar.
   */
  size?: AvatarSize

  /**
   * A URL to an icon that, if provided, is placed over any status indicator.
   *
   * If provided, a mask is automatically applied to the avatar.
   */
  badge?: string
  /**
   * Display a tooltip when hovering the avatar badge
   */
  badgeTooltip?: string
  status?: 'activeSnoozed' | 'active' | 'inactive' | 'inactiveSnoozed' | 'busy'
  label?: string
  'aria-describedby'?: string
  variant?: 'user' | 'workspace'
}

/**
 * Events handlers accepted to integrate with other components like Tooltip
 */
export type AvatarEventHandlerProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'onFocus' | 'onBlur' | 'onPointerEnter' | 'onPointerLeave'
>

export type AvatarProps = AvatarBaseProps & AvatarEventHandlerProps

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      style,
      className,
      circleClassName,
      size = 26,
      label,
      url,
      retinaUrl,
      symbol,
      initials,
      status,
      badge,
      badgeTooltip,
      colorId,
      'aria-describedby': ariaDescribedBy,
      onFocus,
      onBlur,
      onPointerEnter,
      onPointerLeave,
      variant = 'user',
    },
    ref,
  ) => {
    const themeContext = useTheme()
    const isUserAvatar = variant === 'user'

    const backgroundColor = isUserAvatar
      ? initials && getBackgroundColor(colorId)
      : undefined

    const defaultBackgroundImageUrl = themeContext.match({
      dark: isUserAvatar ? defaultDarkUrl : defaultWorkspaceDarkUrl,
      light: isUserAvatar ? defaultLightUrl : defaultWorkspaceLightUrl,
    })

    const showInitials = !url && isUserAvatar && !!backgroundColor

    const circleStyles = useMemo(() => {
      let baseStyles: Record<string, unknown> = {
        width: size,
        height: size,
        fontSize: fontSizesByAvatarSize[size],
      }

      if (url) {
        baseStyles = {
          ...baseStyles,
          backgroundImage: `-webkit-image-set(url("${url}") 1x, url("${
            retinaUrl || url
          }") 2x)`,
        }
      } else if (backgroundColor) {
        baseStyles = {
          ...baseStyles,
          backgroundColor,
        }
      } else {
        baseStyles = {
          ...baseStyles,
          backgroundImage: `-webkit-image-set(url("${defaultBackgroundImageUrl}") 1x, url("${
            retinaUrl || defaultBackgroundImageUrl
          }") 2x)`,
        }
      }

      if (status) {
        baseStyles = {
          ...baseStyles,
          maskImage: getAvatarStatusMaskUrl(size),
          WebkitMaskImage: getAvatarStatusMaskUrl(size),
        }
      }

      if (badge) {
        const value = `url("${badgeMaskUrl}")`

        baseStyles = {
          ...baseStyles,
          maskImage: value,
          WebkitMaskImage: value,
        }
      }

      if (symbol) {
        baseStyles = {
          ...baseStyles,
          backgroundImage: null,
          background: `rgb(${theme.palette.overlay.strong})`,
          fontSize: symbolFontSizesByAvatarSize[size],
        }
      }

      return baseStyles
    }, [
      url,
      backgroundColor,
      retinaUrl,
      defaultBackgroundImageUrl,
      size,
      status,
      badge,
      symbol,
    ])

    return (
      <div
        ref={ref}
        role="img"
        aria-label={label}
        aria-hidden={!label}
        className={classNames(styles.root, className)}
        style={style}
        aria-describedby={ariaDescribedBy}
        onFocus={onFocus}
        onBlur={onBlur}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        <div
          className={classNames(
            styles.circle,
            {
              [styles.statusMask]: status,
              [styles.badgeMask]: badge,
            },
            circleClassName,
          )}
          style={circleStyles}
        >
          {symbol || (showInitials ? initials : null)}
        </div>

        {badge ? (
          <Tooltip title={badgeTooltip ?? ''} disabled={!badgeTooltip}>
            <div
              className={styles.badge}
              style={{
                ...getAvatarBadgeCssStyles(size),
                backgroundImage: `url("${badge}")`,
              }}
            />
          </Tooltip>
        ) : status ? (
          <div className={styles.status({ status, size })} />
        ) : null}
      </div>
    )
  },
)

export default Avatar

export const fontSizesByAvatarSize: Record<AvatarSize, number> = {
  14: 6,
  16: 6,
  18: 7,
  20: 8,
  22: 8,
  24: 9,
  26: 10,
  30: 11,
  34: 13,
  36: 14,
  40: 15,
  48: 18,
  56: 21,
  80: 30,
  96: 36,
}

const symbolFontSizesByAvatarSize: Record<AvatarSize, number> = {
  14: 8,
  16: 9,
  18: 10,
  20: 11,
  22: 12,
  24: 13,
  26: 14,
  30: 17,
  34: 19,
  36: 20,
  40: 22,
  48: 27,
  56: 31,
  80: 44,
  96: 53,
}

function getAvatarBadgeCssStyles(size: AvatarSize): React.CSSProperties {
  switch (size) {
    case 14:
    case 16:
      return { width: 5, height: 5, bottom: -1, right: -1 }
    case 18:
      return { width: 6, height: 6, bottom: -1, right: -1 }
    case 20:
    case 22:
      return { width: 7, height: 7, bottom: -2, right: -2 }
    case 24:
      return { width: 8, height: 8, bottom: -2, right: -2 }
    case 26:
      return { width: 9, height: 9, bottom: -2, right: -2 }
    case 30:
      return { width: 10, height: 10, bottom: -2, right: -2 }
    case 34:
      return { width: 11, height: 11, bottom: -2, right: -2 }
    case 36:
      return { width: 12, height: 12, bottom: -2, right: -2 }
    case 40:
      return { width: 13, height: 13, bottom: -3, right: -3 }
    case 48:
      return { width: 16, height: 16, bottom: -3, right: -3 }
    case 56:
      return { width: 19, height: 19, bottom: -4, right: -4 }
    case 80:
      return { width: 27, height: 27, bottom: -5, right: -5 }
    case 96:
      return { width: 32, height: 32, bottom: -6, right: -6 }
    default:
      assertNever(size, `Unhandled avatar size: ${size}`)
  }
}

function getAvatarStatusMaskUrl(size: AvatarSize): string {
  switch (size) {
    case 14:
    case 16:
    case 18:
    case 20:
      return `url("${mask.tiny}")`
    case 22:
    case 24:
    case 26:
    case 30:
      return `url("${mask.small}")`
    case 34:
    case 36:
    case 40:
    case 48:
    case 56:
      return `url("${mask.medium}")`
    case 80:
    case 96:
      return `url("${mask.large}")`
    default:
      assertNever(size, `Unhandled avatar size: ${size}`)
  }
}

function getBackgroundColor(id?: string): string | null {
  if (!id) {
    return null
  }

  let sum = 0

  for (let i = 0; i < id.length; i++) {
    sum += id.charCodeAt(i)
  }

  return backgroundColors[sum % backgroundColors.length] ?? null
}
