import { mergeProps, mergeRefs } from '@react-aria/utils'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import type { CSSProperties } from 'react'
import { forwardRef, useMemo, useRef, useEffect } from 'react'
import type { AriaPositionProps, PositionAria } from 'react-aria'
import { useOverlayPosition, DismissButton, useOverlay, FocusScope } from 'react-aria'

import OverlayContainer from '@ui/OverlayContainer'
import type { Sensor } from '@ui/SensorProvider'
import { useSensor } from '@ui/SensorProvider'
import { setBaseZIndex, useLayer } from '@ui/z-index'

import * as styles from './Popover.css'

export interface PopoverProps {
  children: React.ReactNode

  /**
   * Handler that is called when the overlay should close.
   */
  onClose?: (sensor: Sensor) => void

  /**
   * Whether to close the overlay when the user interacts outside it.
   *
   * @default false
   */
  isDismissable?: boolean

  /**
   * Whether the overlay should close when focus is lost or moves outside it.
   */
  shouldCloseOnBlur?: boolean

  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   *
   * @default false
   */
  isKeyboardDismissDisabled?: boolean

  /**
   * Whether the overlay should close when the user scrolls the page.
   *
   * @default true
   */
  shouldCloseOnScroll?: boolean

  /**
   * When user interacts with the argument element outside of the overlay ref,
   * return true if onClose should be called.  This gives you a chance to filter
   * out interaction with elements that should not dismiss the overlay.
   * By default, onClose will always be called on interaction outside the overlay ref.
   */
  shouldCloseOnInteractOutside?: (element: Element) => boolean

  /**
   * The ref for the element which the overlay positions itself with respect to.
   */
  targetRef: React.RefObject<Element>

  /**
   * The placement of the element with respect to its anchor element.
   *
   * @default 'bottom'
   */
  placement?: AriaPositionProps['placement']

  /**
   * The placement padding that should be applied between the element and its
   * surrounding container.
   *
   * @default 12
   */
  containerPadding?: number

  /**
   * The additional offset applied along the main axis between the element and its
   * anchor element.
   *
   * @default 0
   */
  offset?: number

  /**
   * The additional offset applied along the cross axis between the element and its
   * anchor element.
   *
   * @default 0
   */
  crossOffset?: number

  /**
   * Whether the element should flip its orientation (e.g. top to bottom or left to right) when
   * there is insufficient room for it to render completely.
   *
   * @default true
   */
  shouldFlip?: boolean

  /**
   * Contain focus within the overlay. Focus will always be contained when
   * `withUnderlay = true` is used.
   */
  containFocus?: boolean

  /**
   * Automatically focus the first focusable element when the overlay is opened.
   */
  autoFocus?: boolean

  /**
   * Custom arrow element. Make sure to also use `offset` to give space for the arrow.
   */
  arrow?: React.ReactNode

  /**
   * Classname to apply to the popover
   */
  className?: string

  /**
   * The style object that can be applied to the popover
   */
  style?: CSSProperties

  /*
   * The z-index of the popover. If specified, should override the default one
   */
  zIndex?: number

  /**
   * Whether or not to render an underlay behind the popover that prevents pointer
   * events in the background and keeps focus in the popover. Clicking on the
   * underlay will close the popover when `isDismissable = true`.
   */
  withUnderlay?: boolean
}

const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      children,
      targetRef,
      containFocus,
      autoFocus,
      isDismissable,
      arrow,
      onClose: _onClose,
      zIndex,
      shouldCloseOnScroll = true,
      withUnderlay = true,
      ...props
    },
    forwardedRef,
  ) => {
    const layer = useLayer()
    const overlayRef = useRef<HTMLDivElement>(null)

    const sensor = useSensor()
    const onClose = () => {
      _onClose?.(sensor)
    }

    const deferredOnClose = useDeferredOnClose(onClose)

    const { overlayProps, underlayProps } = useOverlay(
      {
        isOpen: true,
        isDismissable,
        onClose,
        ...props,
      },
      overlayRef,
    )

    const {
      overlayProps: overlayPositionProps,
      arrowProps,
      placement,
      updatePosition,
    } = useOverlayPosition({
      overlayRef,
      targetRef,
      isOpen: true,
      onClose: shouldCloseOnScroll ? deferredOnClose : undefined,
      ...props,
    })

    // we don't need this z-index because we're applying our own based on the layer
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    delete overlayPositionProps.style?.zIndex

    useEffect(() => {
      document.addEventListener('scroll', updatePosition, true)
      return () => document.removeEventListener('scroll', updatePosition)
    }, [updatePosition])

    const placementStyles = useMemo(() => {
      return {
        popover: {
          transformOrigin: getPopoverTransformOrigin(placement),
        },
        arrow: getArrowStyles(placement),
      }
    }, [placement])

    const { style: overlayMergedStyle, ...overlayMergedRestProps } = mergeProps(
      overlayProps,
      overlayPositionProps,
    )

    const { style: underlayStyle, ...underlayRestProps } = underlayProps

    return (
      <OverlayContainer>
        <FocusScope
          contain={withUnderlay || containFocus}
          autoFocus={autoFocus}
          restoreFocus={true}
        >
          {withUnderlay ? (
            <div
              className={styles.underlay}
              data-underlay="true"
              {...underlayRestProps}
              style={{
                ...underlayStyle,
                ...assignInlineVars({ ...setBaseZIndex(layer.zIndex) }),
                zIndex,
              }}
            />
          ) : null}
          <div
            {...overlayMergedRestProps}
            ref={mergeRefs(overlayRef, forwardedRef)}
            className={styles.root}
            style={{
              ...overlayMergedStyle,
              ...assignInlineVars({ ...setBaseZIndex(layer.zIndex) }),
              zIndex,
            }}
            // Prevent the Popover from propagating clicks to the parent React tree.
            // This is useful when rendering a Popover from inside a button or link.
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={props.className}
              style={{ ...placementStyles.popover, ...props.style }}
            >
              <div>
                {children}

                {isDismissable ? <DismissButton onDismiss={onClose} /> : null}
              </div>

              <span
                className={styles.arrow}
                {...arrowProps}
                style={{ ...arrowProps.style, ...placementStyles.arrow }}
              >
                {arrow}
              </span>
            </div>
          </div>
        </FocusScope>
      </OverlayContainer>
    )
  },
)

Popover.displayName = 'Popover'

export default Popover

function getPopoverTransformOrigin(placement: PositionAria['placement']) {
  switch (placement) {
    case 'top':
      return 'bottom center'
    case 'bottom':
      return 'top center'
    case 'left':
      return 'right center'
    case 'right':
      return 'left center'
    default:
      return 'top center'
  }
}

function getArrowStyles(placement: PositionAria['placement']) {
  const css: Record<string, unknown> = {}

  switch (placement) {
    case 'top':
      css.transform = 'translateX(-50%)'
      break
    case 'bottom':
      css.transform = 'translateX(-50%) rotate(-180deg)'
      css.bottom = '100%'
      break
    case 'left':
      css.transform = 'translate(50%, -50%) rotate(-90deg)'
      css.left = '100%'
      break
    case 'right':
      css.transform = 'translate(-50%, -50%) rotate(90deg)'
      css.right = '100%'
      break
    default:
      css.transform = 'translateX(-50%)'
      break
  }

  return css
}

// The `useOverlayPosition` hook from react aria listens for scroll
// events on the window and automatically closes the `Popover`
// if there are any events.
//
// For some reason the `OverlayContainer` component, that wraps the `Popover`'s
// content, triggers an scroll event, therefore the `Popover` automatically closes.
//
// This hook allow us to mount the Popover with a `noop` onClose callback and immediately
// schedule for the next tick the real one, so to the user's eyes, this hack, is imperceptible.
function useDeferredOnClose(onClose?: () => void): () => void {
  const onCloseRef = useRef<() => void>(() => void {})

  const calledRef = useRef(false)

  useEffect(() => {
    setTimeout(() => {
      if (!onClose) {
        return
      }
      onCloseRef.current = onClose
    })
  }, [onClose])

  return () => {
    if (calledRef.current) {
      return
    }
    calledRef.current = true
    onCloseRef.current()
  }
}
