import type { Survicate } from '@survicate/survicate-web-surveys-wrapper/'
import {
  initSurvicate,
  getSurvicateInstance,
  AppearMethod,
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper'
import { makeAutoObservable, reaction } from 'mobx'

import config from '@src/config'
import Deferred from '@src/lib/Deferred'
import { DisposeBag } from '@src/lib/dispose'
import type Service from '@src/service'
import type { UserModel } from '@src/service/model'

export default class SurveyService {
  private survicate: Survicate | null = null
  private survicateInitialization = new Deferred<void>()
  private disposeBag = new DisposeBag()

  constructor(root: Service) {
    makeAutoObservable(this)

    this.disposeBag.add(
      reaction(
        () =>
          [
            this.survicate,
            root.flags.getFlag('webSurvicate'),
            root.auth.hasSession || root.authV2.isAuthenticated,
          ] as const,
        ([survicate, surveyId, isLoggedIn]) => {
          if (survicate && surveyId && isLoggedIn) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
            this.showSurvey(surveyId, {
              displayMethod: AppearMethod.exitIntent,
            })
          }
        },
      ),
    )

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.initSurvicate()
  }

  private async initSurvicate() {
    if (this.survicateInitialization.state === 'fulfilled') {
      return
    }

    try {
      await initSurvicate({
        workspaceKey: config.SURVICATE_WORKSPACE_KEY,
        // Since we have our own logic for determining when to show a survey,
        // prevent a survey from appearing immediately after loading the page.
        // See "Disable automatic targeting" on the docs:
        // https://developers.survicate.com/javascript/methods/
        disableTargeting: true,
      })
      this.survicate = getSurvicateInstance()
      this.survicateInitialization.resolve()
    } catch (error) {
      this.survicateInitialization.reject(error)
    }
  }

  async showSurvey(
    surveyId: Parameters<Survicate['showSurvey']>[0],
    options?: Parameters<Survicate['showSurvey']>[1],
  ): Promise<ReturnType<Survicate['showSurvey']>> {
    if (!surveyId || this.survicateInitialization.state === 'rejected') {
      return false
    }

    await this.survicateInitialization.promise

    if (!this.survicate) {
      return false
    }

    return this.survicate.showSurvey(surveyId, options)
  }

  async identify(user: UserModel) {
    if (this.survicateInitialization.state === 'rejected') {
      return
    }

    await this.survicateInitialization.promise

    if (!this.survicate) {
      return
    }

    this.survicate.setVisitorTraits({
      userId: user.id,
    })
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
