import { theme, useTheme } from '@ui/theme'

const Logo = function ({ className }: { className?: string }) {
  const { themeKey } = useTheme()
  return (
    <svg
      version="1.1"
      width="195"
      height="30"
      viewBox="0 0 172.99999999999994 24.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>
        <clipPath id="i0">
          <path d="M8.43513402,0 C13.0874839,0 16.8715972,3.82290968 16.8715972,8.52454005 C16.8715972,13.2261438 13.0874839,17.0504134 8.43513402,17.0504134 L8.35804014,17.0504134 C3.74157875,17.0078801 0,13.2249438 0,8.52454005 C0,3.82418968 3.78398039,0 8.43513402,0 Z M8.43513402,3.4312027 C5.77366713,3.4312027 3.74237627,5.71695117 3.74237627,8.52583338 C3.74237627,11.3346756 5.77247085,13.6204107 8.43513402,13.6204107 C11.0977972,13.6204107 13.1278918,11.3346756 13.1278918,8.52583338 C13.1278918,5.71695117 11.0966009,3.4312027 8.43513402,3.4312027 Z" />
        </clipPath>
        <clipPath id="i1">
          <path d="M6.34097177,0 C9.83810974,0 12.6812789,2.86426892 12.6812789,6.38440503 C12.6812789,9.90454114 9.83810974,12.7688101 6.34097177,12.7688101 C2.845163,12.7688101 0,9.90454114 0,6.38440503 C0,2.86426892 2.845163,0 6.34097177,0 Z M6.34097177,2.72838882 C4.4335628,2.72838882 3.15220932,4.29319005 3.15220932,6.3830717 C3.15220932,8.47294001 4.43223359,10.0377412 6.34097177,10.0377412 C8.24838074,10.0377412 9.52973422,8.47294001 9.52973422,6.3830717 C9.52973422,4.29319005 8.24838074,2.72838882 6.34097177,2.72838882 Z" />
        </clipPath>
        <clipPath id="i2">
          <path d="M8.00500333,0 C11.330541,0 13.8721136,2.75384217 13.8721136,6.33861833 C13.8721136,9.94702117 11.330541,12.70089 7.74368165,12.70089 C6.14172396,12.70089 4.53976627,12.0582228 3.51561392,10.8710219 L3.51561392,15.8820925 L0,15.8820925 L0,0.379840299 L3.32553763,0.379840299 L3.32553763,3.11388245 C4.29572425,0.940054074 5.95350855,0 8.00500333,0 Z M7.00730216,2.61140206 C5.01203275,2.61140206 3.49182116,4.20197664 3.51561392,6.33861833 C3.49182116,8.47515334 5.01203275,10.0895546 7.00730216,10.0895546 C8.90766634,10.0895546 10.309047,8.4988867 10.309047,6.33861833 C10.309047,4.17824329 9.00270449,2.61140206 7.00730216,2.61140206 Z" />
        </clipPath>
        <clipPath id="i3">
          <path d="M8.17128685,0 C11.2829554,0 13.1831867,2.20781507 13.1831867,5.79259123 L13.1831867,12.4872632 L9.69149845,12.4872632 L9.69149845,6.02992475 C9.69149845,3.84588303 8.64634467,2.63513541 6.79356602,2.63513541 C5.1070709,2.63513541 3.515481,4.13076325 3.515481,6.02992475 L3.515481,12.4872632 L0,12.4872632 L0,0.451053689 L3.18291395,0.451053689 L3.18291395,3.25137589 C4.45961521,0.982440774 6.2743786,0 8.17128685,0 Z" />
        </clipPath>
        <clipPath id="i4">
          <path d="M3.49182116,0 L3.49182116,6.5646585 C4.86834571,4.67867035 6.56693659,3.87336305 8.14749409,3.89336307 C11.2829554,3.9408431 13.2069795,6.14865818 13.2069795,9.68594096 L13.2069795,16.3806129 L9.71529122,16.3806129 L9.71529122,9.92327448 C9.71529122,7.7392461 8.67013744,6.52851181 6.79356602,6.52851181 C5.1070709,6.52851181 3.49182116,8.02412632 3.49182116,9.92327448 L3.49182116,16.3806129 L0,16.3806129 L0,0 L3.49182116,0 Z" />
        </clipPath>
        <clipPath id="i5">
          <path d="M8.17195146,0 C11.28362,0 13.184383,2.20781507 13.184383,5.79259123 L13.184383,12.4872632 L9.69256181,12.4872632 L9.69256181,6.02992475 C9.69256181,3.84588303 8.64647759,2.63513541 6.79356602,2.63513541 C5.10813426,2.63513541 3.51574685,4.13076325 3.51574685,6.02992475 L3.51574685,12.4872632 L0,12.4872632 L0,0.451053689 L3.18344563,0.451053689 L3.18344563,3.25137589 C4.45948229,0.982440774 6.27517613,0 8.17195146,0 Z" />
        </clipPath>
        <clipPath id="i6">
          <path d="M8.76504266,0 C11.8292586,0 14.0146043,2.30277515 14.0146043,5.53141769 C14.0146043,8.76006023 11.8292586,11.0391554 8.76504266,11.0391554 L3.68176453,11.0391554 L3.68176453,16.3806262 L0,16.3806262 L0,0 L8.76504266,0 Z M7.79113427,2.84878891 L3.68176453,2.84878891 L3.68176453,8.23776649 L7.79113427,8.23776649 C9.24010048,8.23776649 10.1664233,7.193219 10.1664233,5.53141769 C10.1664233,3.89334973 9.24010048,2.84878891 7.79113427,2.84878891 Z" />
        </clipPath>
        <clipPath id="i7">
          <path d="M6.34097177,0 C9.83704638,0.000333333596 12.6804814,2.86448226 12.6804814,6.38440503 C12.6804814,6.75160532 12.6496439,7.12013894 12.5866395,7.48080589 L10.3323081,7.48080589 L3.29536468,7.48253923 L3.35012792,7.64960603 C3.70662066,9.15974055 4.87977687,10.1750747 6.34150345,10.1750747 C7.40313937,10.1750747 8.30354274,9.75760769 8.81927422,9.02827378 L12.1114488,9.02827378 C11.0799858,11.3064089 8.8360222,12.7688101 6.34150345,12.7688101 C2.845163,12.7688101 0,9.90454114 0,6.38440503 C0,2.86448226 2.84489715,0.00034666694 6.34097177,0 Z M6.34150345,2.59369538 C4.73648859,2.59369538 3.69492366,3.61416285 3.34494402,5.13456404 L3.32872772,5.18548409 L9.35308289,5.18548409 L9.33686659,5.13456404 C8.98688695,3.61545618 7.94651831,2.59369538 6.34150345,2.59369538 Z" />
        </clipPath>
        <clipPath id="i8">
          <path d="" />
        </clipPath>
        <clipPath id="i9">
          <path d="M6.34030716,0 C9.83611594,0.00034666694 12.6792851,2.86448226 12.6792851,6.38440503 C12.6792851,6.75160532 12.6487134,7.12013894 12.5862408,7.48080589 L10.3319093,7.48080589 L3.29509884,7.48253923 L3.34959624,7.64960603 C3.70582314,9.15974055 4.74659054,10.1750747 6.34030716,10.1750747 C7.40234184,10.1750747 8.30354274,9.75760769 8.81927422,9.02827378 L12.1103854,9.02827378 C11.0789225,11.3064089 8.83522468,12.7688101 6.34030716,12.7688101 C2.84449839,12.7688101 0,9.90454114 0,6.38440503 C0,2.86448226 2.84449839,0.000333333596 6.34030716,0 Z M6.34030716,2.59369538 C4.7359569,2.59369538 3.6938603,3.61416285 3.34427942,5.13456404 L3.32832896,5.18548409 L9.35228537,5.18548409 L9.33633491,5.13456404 C8.98675403,3.61545618 7.94598663,2.59369538 6.34030716,2.59369538 Z" />
        </clipPath>
        <clipPath id="i10">
          <path d="M20.9377681,0 C26.3766086,0.0010211408 31.1309085,3.68129623 32.5095598,8.95882039 C33.8882111,14.2362779 31.5441583,19.7825489 26.8042138,22.4578844 C22.0642693,25.1332198 16.1216602,24.2642858 12.3402983,20.3429494 C15.7296777,18.9678816 17.9481206,15.6672123 17.9481206,11.9998361 C17.9481206,8.3324199 15.7296777,5.03183063 12.3402983,3.65670955 C14.5670222,1.34226772 17.6275164,0.0255134868 20.8309001,0.00035968295 L20.9377681,0 Z M12.0779495,3.55489623 L12.3402983,3.65670955 C7.9313036,8.2282966 7.85528645,15.4374479 12.1122469,20.0999348 L12.3402983,20.3429494 C8.59236599,21.8650839 4.29257437,20.6845496 1.83967302,17.4601471 C-0.613224341,14.2357445 -0.613224341,9.76395436 1.83967302,6.53953849 C4.23553015,3.390096 8.39337454,2.19066531 12.0779495,3.55489623 Z" />
        </clipPath>
      </defs>
      <g transform="translate(41.32152338339962 3.600002836023563)">
        <g clipPath="url(#i0)">
          <polygon
            points="0,0 16.8715972,0 16.8715972,17.0504134 0,17.0504134 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          />
        </g>
      </g>
      <g transform="translate(132.1954047175337 7.893206218139211)">
        <g clipPath="url(#i1)">
          <polygon
            points="0,0 12.6812789,0 12.6812789,12.7688101 0,12.7688101 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          />
        </g>
      </g>
      <g transform="translate(59.25648451004493 7.966059608865261)">
        <g clipPath="url(#i2)">
          <polygon
            points="0,0 13.8721136,0 13.8721136,15.8820925 0,15.8820925 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          />
        </g>
      </g>
      <g transform="translate(87.66996628070432 7.851019518238547)">
        <g clipPath="url(#i3)">
          <polygon
            points="0,0 13.1831867,0 13.1831867,12.4872632 0,12.4872632 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          />
        </g>
      </g>
      <g transform="translate(117.7921408941933 3.9458031084393763)">
        <g clipPath="url(#i4)">
          <polygon
            points="0,0 13.2069795,0 13.2069795,16.3806129 0,16.3806129 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(146.0729680201896 7.851019518238547)">
        <g clipPath="url(#i5)">
          <polygon
            points="0,0 13.184383,0 13.184383,12.4872632 0,12.4872632 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(102.5812522001388 3.95765645111058)">
        <g clipPath="url(#i6)">
          <polygon
            points="0,0 14.0146043,0 14.0146043,16.3806262 0,16.3806262 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(73.92612098659626 7.893206218139211)">
        <g clipPath="url(#i7)">
          <polygon
            points="0,0 12.6804814,0 12.6804814,12.7688101 0,12.7688101 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(80.20056605072378 7.82653949895359)">
        <g clipPath="url(#i8)">
          <polygon
            points="0.0658621006,0.0666667192 0.067058385,0.0666667192 0.067058385,0.0666667192 0.0658621006,0.0666667192 0.0658621006,0.0666667192"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(160.32071487533767 7.893206218139211)">
        <g clipPath="url(#i9)">
          <polygon
            points="0,0 12.6792851,0 12.6792851,12.7688101 0,12.7688101 0,0"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g transform="translate(166.5938971948517 7.82653949895359)">
        <g clipPath="url(#i8)">
          <polygon
            points="0.0657956404,0.0666667192 0.0671248452,0.0666667192 0.0671248452,0.0666667192 0.0657956404,0.0666667192 0.0657956404,0.0666667192"
            stroke="none"
            fill={
              themeKey === 'light'
                ? `rgb(${theme.palette.locked.black})`
                : `rgb(${theme.palette.locked.white})`
            }
          ></polygon>
        </g>
      </g>
      <g clipPath="url(#i10)">
        <polygon
          points="0,0 32.9013535,0 32.9013535,24 0,24 0,0"
          stroke="none"
          fill={
            themeKey === 'light' ? `rgb(${theme.palette.primary.default})` : '#744DF6'
          }
        ></polygon>
      </g>
    </svg>
  )
}

export default Logo
