import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import Logo from '@src/component/logo'
import useSize from '@src/lib/hooks/useSize'
import Typography from '@ui/Typography'

import * as styles from './Header.css'

const Header = function () {
  const { service, signOut } = useAppStore()
  const ref = useRef<HTMLDivElement>(null)
  const [isMobile, setIsMobile] = useState(false)

  const isAuthenticated = service.auth.hasSession || service.authV2.isAuthenticated

  useSize({
    ref,
    onResize: ({ width }) => setIsMobile(width <= 520),
  })

  return (
    <div ref={ref} className={styles.root}>
      <Logo className={styles.logo} />
      {isAuthenticated && (
        <Button
          variant="text"
          color="textSecondary"
          height={isMobile ? 36 : 32}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
          onClick={() => signOut()}
          className={styles.logoutButton}
        >
          <Typography variant="caption1" color="textSecondary">
            Log out
          </Typography>
        </Button>
      )}
    </div>
  )
}

export default observer(Header)
