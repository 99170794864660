import { useEffect } from 'react'

export default function useMetaTag(name: string, content: string, deps?: any[]): void {
  useEffect(() => {
    let el = document.head.querySelector<HTMLMetaElement>(`meta[name="${name}"]`)

    if (el) {
      el.content = content
    } else {
      el = document.createElement('meta')
      el.setAttribute('name', name)
      el.content = content
      document.head.appendChild(el)
    }

    return () => {
      el?.remove()
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, deps)
}
