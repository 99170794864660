import type { Auth0Client } from '@auth0/auth0-spa-js'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import { parsePhoneNumber } from 'libphonenumber-js'
import { action, makeAutoObservable } from 'mobx'

import config, { platform, deviceId } from '@src/config'
import StatefulPromise from '@src/lib/StatefulPromise'
import { logError } from '@src/lib/log'

import type Service from '.'
import UniversalLoginCacheLocation from './UniversalLoginCacheLocation'
import makePersistable from './storage/makePersistable'
import type { Invite } from './transport/account'

class UniversalLoginAuthStore {
  invite: Invite | null = null
  inviteToken: string | null = null
  isAuthenticated = false
  auth0Client: Auth0Client | null = null
  /**
   * A timestamp when the session was started.
   * Used to determine when the user should be logged out.
   * Currently, all the sessions have a 30-day expiration time.
   */
  sessionStartedOn: number | null = null
  /**
   * A flag that indicates if the user should see the modal
   * with the reason for the logout.
   */
  displayLogoutReason = false

  /**
   * A phone number that a user selected before the signup.
   * Used to preselect the phone number during the onboarding
   */
  private _phoneNumber: string | null = null

  onRefreshTokenError: ((err: unknown) => void) | null = null

  private acceptInvitePromise = new StatefulPromise(this.handleAcceptInvite.bind(this))

  constructor(private service: Service) {
    makeAutoObservable(
      this,
      { auth0Client: false, isSessionAboutToExpire: false },
      { autoBind: true },
    )

    makePersistable(this, 'UniversalLoginAuthStore', {
      sessionStartedOn: this.service.storage.sync(),
      displayLogoutReason: this.service.storage.sync(),
    })
  }

  // we want to enable it only if the feature flag is enabled and the user is not logged in using the old auth method
  get isEnabled(): boolean {
    return (
      Boolean(this.service.flags.flags.webUniversalLogin) && !this.service.auth.hasSession
    )
  }

  async init() {
    const isElectron = Boolean(this.service.desktopVersion)
    this.auth0Client = await createAuth0Client({
      authorizationParams: {
        scope: 'openid profile email offline_access',
        audience: config.AUTH0_UNIVERSAL_LOGIN_AUDIENCE,

        redirect_uri: isElectron
          ? `${window.location.origin}/native/login`
          : `${window.location.origin}/auth/authorize`,
        opApp: 'web',
        opAppVersion: config.VERSION,
        opDevice: platform ?? 'browser',
        opDeviceId: deviceId,
        opDesktopVersion: this.service.desktopVersion,
      },
      useRefreshTokens: true,
      domain: config.AUTH0_UNIVERSAL_LOGIN_DOMAIN,
      clientId: config.AUTH0_UNIVERSAL_LOGIN_CLIENT_ID,

      cache: new UniversalLoginCacheLocation(this.service),
    })

    await this.checkIfAuthenticated()
  }

  hideLogoutReason() {
    this.displayLogoutReason = false
  }

  showLogoutReason() {
    this.displayLogoutReason = true
  }

  logout(displayLogoutReason = false) {
    if (displayLogoutReason) {
      this.showLogoutReason()
    }

    return this.auth0Client?.logout({
      openUrl: false,
    })
  }

  async getAccessToken(): Promise<string> {
    if (!this.auth0Client || !this.isAuthenticated) {
      return ''
    }

    try {
      const token = await this.auth0Client?.getTokenSilently({})
      return token
    } catch (err) {
      Sentry.captureMessage(
        'Logging out the user due to failure to obtain a new auth token',
        'debug',
      )

      this.onRefreshTokenError?.(err)

      this.isAuthenticated = false

      await this.service.clearAllAndRestart().catch(logError)

      return ''
    }
  }

  async checkIfAuthenticated(): Promise<boolean> {
    if (!this.auth0Client) {
      this.isAuthenticated = false
      return this.isAuthenticated
    }

    this.isAuthenticated = await this.auth0Client?.isAuthenticated()
    return this.isAuthenticated
  }

  fetchAndSetInvite(token: string) {
    this.inviteToken = token
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.service.user.fetchInviteByToken(token).then(
      action((invite) => {
        this.invite = invite
      }),
    )
  }

  /**
   * Check if the session is about to expire.
   * If the current day is within 5 days of the session expiration date,
   * we consider the session as about to expire.
   */
  get isSessionAboutToExpire(): boolean {
    if (!this.sessionStartedOn) {
      return false
    }

    const twentyFiveDaysInMilliseconds = 25 * 1000 * 60 * 60 * 24

    // comparing the difference in epoch time between the current time and the session start time
    return this.sessionStartedOn - new Date().getTime() > twentyFiveDaysInMilliseconds
  }

  get showTransitionBanner(): boolean {
    const cutOffDateValue = this.service.flags.getFlag('webUniversalLoginCutOffDate', '')
    if (this.isEnabled || cutOffDateValue === '') {
      return false
    }

    const cutOffDate = new Date(cutOffDateValue)
    const now = dayjs(new Date())
    const diffInDays = dayjs(cutOffDate).diff(now, 'day')

    return diffInDays <= 7 && diffInDays >= 0
  }

  get isAcceptingInvite() {
    return this.acceptInvitePromise.status === 'loading'
  }

  get phoneNumber(): string | null {
    return this._phoneNumber
  }

  set phoneNumber(phoneNumber: string) {
    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

      if (parsedPhoneNumber?.isValid()) {
        this._phoneNumber = phoneNumber
      }
    } catch (error) {
      this._phoneNumber = null
    }
  }

  private async handleAcceptInvite(token: string) {
    await this.service.transport.account.invites.accept(token)

    // removing all the query params
    window.location.replace(location.pathname)
  }

  async acceptReferral(token: string) {
    return this.service.referral.accept(token)
  }

  async acceptInvite(token: string) {
    return this.acceptInvitePromise.run(token)
  }
}

export default UniversalLoginAuthStore
