import { makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'

import type { Model } from './base'

export class Invite implements Model {
  id = ''
  email = ''
  orgId = ''
  org: {
    id: string
    name: string
    pictureUrl: string
    numMembers?: number
  } | null = null
  role = ''
  sentBy = ''
  token = ''
  updatedAt: number | null = null
  createdAt: number = Date.now()
  acceptedAt: number | null = null
  cancelledAt: number | null = null
  needsNewNumber = false

  constructor() {
    makeAutoObservable(this, {})
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt) ?? Date.now()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.acceptedAt = parseDate(json.acceptedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.cancelledAt = parseDate(json.cancelledAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      email: this.email,
      org: this.org,
      role: this.role,
      sentBy: this.sentBy,
      token: this.token,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      acceptedAt: this.acceptedAt,
    }
  }
}
