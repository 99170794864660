/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable } from 'mobx'

import type ById from '@src/lib/ById'
import isNonNull from '@src/lib/isNonNull'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import type { CodablePhoneNumber, MemberModel } from './model'
import { PhoneNumberModel } from './model'
import type { AvailablePhoneNumber } from './transport/account'
import type { PhoneNumberRepository } from './worker/repository'

export default class PhoneNumberStore {
  readonly collection: PersistedCollection<PhoneNumberModel, PhoneNumberRepository>

  readonly byGroup: ById<PhoneNumberModel | null> = {}
  readonly byNumber: ById<PhoneNumberModel | null> = {}

  loaded = false
  blockedCountryCodes: string[] | null = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection<PhoneNumberModel, PhoneNumberRepository>({
      table: this.root.storage.table('phoneNumber'),
      classConstructor: (json: CodablePhoneNumber) =>
        new PhoneNumberModel(this.root.phoneNumber, json),
    })

    makeAutoObservable(this, {})
    this.subscribeToWebSocket()
    this.createIndexes()
  }

  fetch() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection
      .performQuery((repo) => repo.all())
      .then(
        action((data) => {
          // TODO: See if this can be a characteristic of the PersistedCollection
          if (data.length > 0) {
            this.loaded = true
          }
        }),
      )

    return this.root.transport.account.workspace.phoneNumbers
      .list()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
      .then(action(() => (this.loaded = true)))
  }

  buy(
    phoneNumber: string,
    consentToSMS: boolean,
    inviteId?: string,
  ): Promise<PhoneNumberModel> {
    // @ts-expect-error unchecked index access
    return this.root.transport.account.phoneNumbers
      .buy(phoneNumber, consentToSMS, inviteId)
      .then(this.collection.load)
      .then((phoneNumbers) => {
        const phoneNumber = phoneNumbers[0]

        this.root.analytics.workspace.numberCreated(
          this.root.organization.current?.id ?? '',
          phoneNumber?.number ?? '',
          'member',
        )
        this.root.analytics.workspace.userAddedToInbox(
          // @ts-expect-error unchecked index access
          phoneNumber?.id,
          phoneNumber?.users.length,
          'owner',
        )
        return phoneNumbers[0]
      })
  }

  verify(phoneNumber: string, recaptcha_token?: string): Promise<void> {
    return this.root.transport.account.verifyPhoneNumber(phoneNumber, recaptcha_token)
  }

  checkCode(phoneNumber: string, code: string): Promise<void> {
    return this.root.transport.account
      .checkPhoneNumberCode(phoneNumber, code)
      .then((user) => {
        this.root.user.current?.deserialize(user)
      })
  }

  update(values: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> {
    return this.root.transport.account.phoneNumbers.update(values)
  }

  mute(values: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> {
    return this.root.transport.account.phoneNumbers.mute(values)
  }

  unmute(values: CodablePhoneNumber): Promise<AvailablePhoneNumber[]> {
    return this.root.transport.account.phoneNumbers.unmute(values)
  }

  getByPortRequestId(id: string): PhoneNumberModel | undefined {
    return this.collection.list.find((item) => item.portRequestId === id)
  }

  getMembers(phoneNumber: PhoneNumberModel): MemberModel[] {
    return phoneNumber.users
      .map((u) => this.root.member.collection.get(u.id ?? null))
      .filter(isNonNull)
  }

  getBlockedCountryCodes() {
    return this.root.transport.account.phoneNumbers
      .getBlockedCountryCodes()
      .then(
        action((blockedCountryCodes) => {
          this.blockedCountryCodes = blockedCountryCodes
        }),
      )
      .catch(
        action(() => {
          this.blockedCountryCodes = []
        }),
      )
  }

  private subscribeToWebSocket() {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'phone-number-update':
          return this.collection.load(data.phoneNumber)
        case 'phone-number-delete':
          return this.collection.delete(data.phoneNumberId)
      }
    })
  }

  protected createIndexes() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((phoneNumber) => {
            if (phoneNumber.groupId) {
              this.byGroup[phoneNumber.groupId] = phoneNumber
            }
            this.byNumber[phoneNumber.number] = phoneNumber
          })
        } else if (event.type === 'delete') {
          event.objects.forEach((phoneNumber) => {
            if (phoneNumber.groupId) {
              this.byGroup[phoneNumber.groupId] = null
            }
            this.byNumber[phoneNumber.number] = null
          })
        }
      }),
    )
  }
}
