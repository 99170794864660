import { action, makeObservable, observable } from 'mobx'

import { parseDate } from '@src/lib'
import StatefulPromise from '@src/lib/StatefulPromise'
import { DisposeBag } from '@src/lib/dispose'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import { CnamProfile } from './model'
import type { CnamProfileRepository } from './worker/repository/CnamProfileRepository'

export default class CnamStore {
  readonly collection: PersistedCollection<CnamProfile, CnamProfileRepository>
  private readonly disposeBag = new DisposeBag()

  private fetchCnamPromise = new StatefulPromise(this.handleFetch.bind(this))

  constructor(private readonly root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table('cnam'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new CnamProfile(json),
      compare: (a, b) => (parseDate(b.createdAt) ?? 0) - (parseDate(a.createdAt) ?? 0),
    })

    makeObservable<this, 'keepOnlyOneDefaultCnam'>(this, {
      collection: observable,
      keepOnlyOneDefaultCnam: action.bound,
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.load()
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  private handleFetch() {
    return this.root.transport.trust.cnam.getAll()
  }

  async fetch() {
    if (this.fetchStatus === 'idle') {
      const response = await this.fetchCnamPromise.run()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.collection.load(response.result)
    }
  }

  get fetchStatus() {
    return this.fetchCnamPromise.status
  }

  async create(displayName: string) {
    const cnam = await this.root.transport.trust.cnam.create({
      displayName,
    })

    const cnamProfile = new CnamProfile(cnam)
    this.collection.put(cnamProfile)

    return cnamProfile
  }

  async delete(id: string) {
    const cnam = this.collection.get(id)

    if (!cnam) {
      return
    }

    await this.root.transport.trust.cnam.delete({
      cnamId: cnam.id,
      etag: cnam.etag,
    })

    this.collection.delete(id)
  }

  async update(
    id: string,
    partial: Partial<Pick<CnamProfile, 'displayName' | 'resourceIds' | 'useAsDefault'>>,
  ) {
    const cnam = this.collection.get(id)

    if (!cnam) {
      return
    }

    try {
      cnam.localUpdate({ updating: true })

      await this.root.transport.trust.cnam.update({
        cnamId: cnam.id,
        etag: cnam.etag,
        ...partial,
      })

      cnam.localUpdate(partial)

      if (partial.useAsDefault) {
        this.keepOnlyOneDefaultCnam(cnam)
      }
    } catch (e) {
      cnam.localUpdate({ updating: false })
      throw e
    }
  }

  async assignPhoneNumber(cnamId: string, phoneNumberId: string) {
    const cnam = this.collection.get(cnamId)

    if (!cnam) {
      return
    }

    await this.update(cnam.id, {
      resourceIds: [...cnam.resourceIds, phoneNumberId],
    })

    const currentCnam = this.getCnamProfileByPhoneNumberId(phoneNumberId)

    if (currentCnam) {
      currentCnam.localUpdate({
        resourceIds: currentCnam.resourceIds.filter((id) => id !== phoneNumberId),
      })
    }
  }

  async removePhoneNumber(cnamId: string, phoneNumberId: string) {
    const cnam = this.collection.get(cnamId)

    if (!cnam) {
      return
    }

    await this.update(cnam.id, {
      resourceIds: cnam.serialize().resourceIds.filter((id) => id !== phoneNumberId),
    })
  }

  getCnamProfileByPhoneNumberId(phoneNumberId: string) {
    return this.collection.list.find((cnam) => cnam.resourceIds.includes(phoneNumberId))
  }

  private keepOnlyOneDefaultCnam(cnamProfile: CnamProfile) {
    this.collection.list.forEach((item) => {
      if (item.id !== cnamProfile.id && item.useAsDefault) {
        item.localUpdate({ useAsDefault: false })
      }
    })
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'cnam-update': {
          const cnam = new CnamProfile(data.cnam)
          this.collection.put(cnam)

          if (cnam.useAsDefault) {
            this.keepOnlyOneDefaultCnam(cnam)
          }
          break
        }
        case 'cnam-delete':
          this.collection.delete(data.cnam.id)
          break
      }
    })
  }
}
