import cx from 'classnames'
import type { ReactNode } from 'react'

import * as styles from './CommandFooter.css'

interface CommandFooterProps {
  rightContent?: ReactNode
  leftContent?: ReactNode
  hideDivider?: boolean
  className?: string
}

const CommandFooter = function ({
  rightContent,
  leftContent,
  hideDivider = false,
  className,
}: CommandFooterProps) {
  return (
    <div className={cx(styles.root({ hideDivider }), className)}>
      <div>{leftContent}</div>
      <div>{rightContent}</div>
    </div>
  )
}

export default CommandFooter
