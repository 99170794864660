/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import Debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { isModel } from '@src/service/model'

import shortId from './shortId'

export interface StepperOptions<T> {
  /**
   * A name for debugging purposes.
   */
  name?: string

  /**
   * The index at which to start.
   */
  index?: number

  /**
   * A hook for skipping items and proceeding during next/prev.
   */
  skip?(item: T, index: number): boolean

  /**
   * Go to start when reaching end and going next
   */
  cycle?: boolean
}

export interface SelectOptions {
  /**
   * When skipping items during selection, the stepper index is advanced by
   * this value until the next appropriate item is found.
   *
   * @default 1
   */
  advanceBy?: -1 | 1

  /**
   * When a "skip" is detected, either proceed to the next/prev index, stay at
   * the current index, or deselect completely.
   *
   * @default 'proceed'
   */
  skipBehavior?: 'proceed' | 'stay' | 'deselect'
}

export interface DataStore<T> {
  data: readonly T[]
}

/**
 * Stepper keeps track of a selected index within an array of items or a
 * collection.
 *
 * The index can be advanced forwards or backwards by one item at a time, or
 * can be moved to the start or end of the array.
 *
 * Stepper has support for `skip`, a function which can be used to skip
 * particular items when moving the index.
 */
export default class Stepper<T> {
  readonly id: string
  readonly debug: Debug.Debugger

  item: T | null = null

  constructor(
    protected store: DataStore<T>,
    protected options: StepperOptions<T> = {},
  ) {
    this.id = this.options.name ?? shortId()
    this.debug = Debug(`op:lib:stepper:@${this.id}`)
    this.select(this.items[this.options.index ?? -1] ?? null)

    this.debug(
      'initiate (index: %O) (items: %O) (options: %O)',
      this.index,
      this.items,
      this.options,
    )

    makeObservable(this, {
      item: observable.ref,
      index: computed,
      items: computed,
      select: action.bound,
      selectIndex: action.bound,
      next: action.bound,
      prev: action.bound,
      start: action.bound,
      end: action.bound,
    })

    reaction(
      () => this.index,
      (index, prevIndex) => {
        this.debug('index change (%O -> %O)', prevIndex, index)
      },
      { name: 'Stepper.IndexChanged' },
    )
  }

  get index() {
    if (isModel(this.item)) {
      return this.items.findIndex(
        (item) => isModel(item) && isModel(this.item) && item.id === this.item.id,
      )
    }

    return this.item === null ? -1 : this.items.indexOf(this.item)
  }

  get items(): readonly T[] {
    return this.store.data
  }

  /**
   * Mark `item` as the selected item.
   *
   * This will adjust the index as needed and handle the case where `item`
   * should be skipped. This is the preferred way to make a new selection.
   */
  select(
    item: T | null,
    { advanceBy = 1, skipBehavior = 'proceed' }: SelectOptions = {},
  ) {
    if (item === null) {
      this.item = null
      return
    }

    const requestedIndex = this.items.indexOf(item)
    const availableIndex = this.findAvailableIndex(requestedIndex, advanceBy, this.index)
    let newIndex = availableIndex
    if (skipBehavior !== 'proceed' && availableIndex !== requestedIndex) {
      this.debug('skip detected during select (behavior: %O)', skipBehavior)
      newIndex = skipBehavior === 'stay' ? this.index : -1
    }
    this.item = newIndex > this.items.length - 1 ? null : this.items[newIndex] ?? null
  }

  /**
   * Set the stepper index to a particular index. Use `null` to reset
   */
  selectIndex(index: number | null, options: SelectOptions = {}): void {
    if (index === null) {
      this.item = null
    } else {
      this.select(this.items[index] ?? null, options)
    }
  }

  /**
   * Step forward to the next suitable index.
   */
  next(): void {
    if (this.options.cycle) {
      this.selectIndex((this.index + 1) % this.items.length)
    } else {
      this.selectIndex(Math.min(this.items.length - 1, this.index + 1))
    }
  }

  /**
   * Step backward to the previous suitable index.
   */
  prev(): void {
    if (this.options.cycle) {
      const index = this.index - 1 < 0 ? this.items.length - 1 : this.index - 1
      this.selectIndex(index, { advanceBy: -1 })
    } else {
      this.selectIndex(Math.max(0, this.index - 1), { advanceBy: -1 })
    }
  }

  /**
   * Set {@link index} to the first suitable index.
   */
  start(): void {
    this.selectIndex(0)
  }

  /**
   * Set {@link index} to the last suitable index.
   */
  end(): void {
    this.selectIndex(this.items.length - 1, { advanceBy: -1 })
  }

  protected findAvailableIndex(
    index: number,
    advanceBy: -1 | 1,
    originalIndex = index,
  ): number {
    const { skip } = this.options

    // @ts-expect-error unchecked index access
    if (skip?.(this.items[index], index)) {
      this.debug('skipping (index: %O)', index)
      const nextIndex = index + advanceBy

      if (nextIndex < 0 || nextIndex >= this.items.length) {
        this.debug(
          'next index out of bounds (nextIndex: %O) (originalIndex: %O)',
          nextIndex,
          originalIndex,
        )

        return originalIndex
      }

      return this.findAvailableIndex(nextIndex, advanceBy, originalIndex)
    }

    return index
  }
}
