import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { HTMLProps } from 'react'
import { useEffect, useContext, forwardRef } from 'react'

import { ModalContext } from '@ui/Modal'

import * as styles from './Command.css'

type CommandAccessibleProps =
  | {
      'aria-label': string
      'aria-labelledby'?: never
    }
  | {
      'aria-labelledby': string
      'aria-label'?: never
    }

type CommandProps = CommandAccessibleProps &
  Omit<HTMLProps<HTMLDivElement>, 'aria-label' | 'aria-labelledby'> & {
    width?: number
  }

const Command = forwardRef<HTMLDivElement, CommandProps>(
  (
    {
      width = 512,
      children,
      className,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      ...props
    },
    ref,
  ) => {
    const { elementRef } = useContext(ModalContext)

    useEffect(() => {
      if (!elementRef.current) {
        return
      }

      const element = elementRef.current

      if (ariaLabel) {
        element.setAttribute('aria-label', ariaLabel)
      }

      if (ariaLabelledBy) {
        element.setAttribute('aria-labelledby', ariaLabelledBy)
      }

      return () => {
        if (ariaLabel) {
          element.removeAttribute('aria-label')
        }

        if (ariaLabelledBy) {
          element.removeAttribute('aria-labelledby')
        }
      }
    })

    return (
      <div
        ref={ref}
        className={cx(styles.root, className)}
        style={assignInlineVars({ [styles.widthVar]: `${width}px` })}
        {...props}
      >
        {children}
      </div>
    )
  },
)

export default Command
