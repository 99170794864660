import Debug from 'debug'
import { useEffect, useState } from 'react'

import useMutexTimeout from './useMutexTimeout'

const debug = Debug('op:use-transition')

export type TransitionState =
  | 'enter'
  | 'entering'
  | 'entered'
  | 'exit'
  | 'exiting'
  | 'exited'

const defaultFallbackDelay = 0
const defaultDelay = 250
const defaultDuration = 200

export interface UseTransitionOptions {
  /**
   * The delay to apply on enter. Can be either a specific value or a boolean.
   * If `true` the default delay is applied; if `false` no delay is applied.
   */
  enterDelay?: boolean | number
  enterDuration?: number
  /**
   * The delay to apply on exit. Can be either a specific value or a boolean.
   * If `true` the default delay is applied; if `false` no delay is applied.
   */
  exitDelay?: boolean | number
  exitDuration?: number
  /**
   * The state the transition should start at. This can be used for example
   * when you need to start in the `entered` state and skip the first transition loop.
   */
  initialState?: TransitionState
}

/**
 * Transition state hook that operates on a single boolean open/closed state.
 *
 * @param enter Whether it should transition in or not.
 */
export default function useTransition(
  enter: boolean,
  options: UseTransitionOptions,
  deps: any[] = [],
): TransitionState {
  const [state, setState] = useState<TransitionState>(options.initialState ?? 'exited')
  const {
    enterDelay: _enterDelay = false,
    enterDuration = defaultDuration,
    exitDelay: _exitDelay = false,
    exitDuration = defaultDuration,
  } = options

  const enterDelay =
    typeof _enterDelay === 'number'
      ? _enterDelay
      : _enterDelay
      ? defaultDelay
      : defaultFallbackDelay

  const exitDelay =
    typeof _exitDelay === 'number'
      ? _exitDelay
      : _exitDelay
      ? defaultDelay
      : defaultFallbackDelay

  debug('(enter: %O) (state: %O) (options: %O)', enter, state, {
    enterDelay,
    enterDuration,
    exitDelay,
    exitDuration,
  })

  const startEnter = () => {
    setState('enter')
    startEnteringDelay()
  }

  const startExit = () => {
    setState('exit')
    startExitingDelay()
  }

  const startEntering = () => {
    setState('entering')
    startEnteredDuration()
  }

  const startExiting = () => {
    setState('exiting')
    startExitedDuration()
  }

  const startEntered = () => setState('entered')
  const startExited = () => setState('exited')

  const [startEnteringDelay, startExitingDelay, cancelDelay] = useMutexTimeout(
    [enterDelay, exitDelay],
    [startEntering, startExiting],
    deps,
  )

  const [startEnteredDuration, startExitedDuration, cancelDuration] = useMutexTimeout(
    [enterDuration, exitDuration],
    [startEntered, startExited],
    deps,
  )

  const cancel = () => {
    cancelDelay()
    cancelDuration()
  }

  useEffect(() => {
    debug('enter value changed (enter: %O)', enter)
    cancel()

    if (enter) {
      if (state === 'exit' || state === 'exiting') {
        startEntering()
      } else if (state !== 'entered') {
        startEnter()
      }
    } else {
      if (state === 'enter' || state === 'entering') {
        startExiting()
      } else if (state !== 'exited') {
        startExit()
      }
    }

    return cancel
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enter])

  return state
}
