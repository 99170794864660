/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import type { ReactNode } from 'react'
import { useContext, createContext } from 'react'

import type { Layer } from '@ui/z-index/zIndex'
import { zIndexes } from '@ui/z-index/zIndex'

interface LayerContextProps {
  layer: Layer
  zIndex: number
}

const defaultValue: LayerContextProps = { layer: 'base', zIndex: zIndexes['base'] }

const LayerContext = createContext<LayerContextProps>(defaultValue)

export const useLayer: () => LayerContextProps = () =>
  useContext(LayerContext) ?? defaultValue

export default function LayerProvider({
  layer,
  children,
}: {
  layer: Layer
  children: ReactNode
}) {
  const parentLayer = useLayer()

  const zIndex = parentLayer.zIndex + zIndexes[layer]

  return (
    <LayerContext.Provider value={{ layer, zIndex }}>{children}</LayerContext.Provider>
  )
}
