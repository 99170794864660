import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type { TemplateTagItemOption } from '@src/lib/search'
import type ContactStore from '@src/service/contact-store'

import type { Model } from './base'

const supportedTypes = [
  'address',
  'boolean',
  'date',
  'multi-select',
  'number',
  'string',
  'url',
  'created-by',
]

export type TemplateType =
  | 'address'
  | 'boolean'
  | 'date'
  | 'multi-select'
  | 'number'
  | 'string'
  | 'url'
  | 'created-by'

export interface CodableContactTemplateItem {
  createdAt?: number | null
  id: string
  key?: string | null
  name?: string | null
  options?: TemplateTagItemOption[] | object
  order?: number | null
  orgId?: string | null
  type: TemplateType | null
  updatedAt?: number | null
}

export class ContactTemplateItemModel implements CodableContactTemplateItem, Model {
  id: string = objectId()
  key: string | null = null
  name: string | null = null
  options: TemplateTagItemOption[] | object = []
  order: number | null = null
  orgId: string | null = null
  type: TemplateType | null = null
  createdAt: number | null = Date.now()
  updatedAt: number | null = Date.now()
  local = false

  constructor(
    private contactStore: ContactStore,
    attrs: Partial<ContactTemplateItemModel> = {},
  ) {
    Object.assign(this, { id: objectId(), key: objectId() }, attrs)
    this.setDefaultOption(this.type)
    makeAutoObservable(this, {})
  }

  get isSupportedType() {
    return this.type ? supportedTypes.includes(this.type) : false
  }

  update(attrs: Partial<CodableContactTemplateItem>) {
    Object.assign(this, attrs)

    return this.contactStore.updateTemplate(this)
  }

  delete() {
    return this.contactStore.deleteTemplate(this)
  }

  deleteOption(option: TemplateTagItemOption) {
    if (!Array.isArray(this.options)) {
      return
    }
    this.options = this.options.filter((item) => item !== option)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.update(this)
  }

  renameOption(option: TemplateTagItemOption, name: string) {
    option.name = name
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.update(this)
  }

  deserialize = (json: Partial<CodableContactTemplateItem>) => {
    Object.assign(this, json)

    this.setDefaultOption(json.type ?? null, json.options)

    if (json.updatedAt) {
      this.updatedAt = parseDate(json.updatedAt)
    }

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt)
    }

    return this
  }

  serialize = (): CodableContactTemplateItem => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      key: this.key,
      name: this.name,
      options: toJS(this.options),
      order: this.order,
      orgId: this.orgId,
      type: this.type,
      updatedAt: this.updatedAt,
    }
  }

  private setDefaultOption(
    type: TemplateType | null,
    options?: TemplateTagItemOption[] | object,
  ) {
    if (options || this.options) {
      this.options = options || this.options
    } else if (type === 'multi-select') {
      this.options = []
    } else {
      this.options = {}
    }
  }
}

export default ContactTemplateItemModel
