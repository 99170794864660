import { makeAutoObservable, toJS } from 'mobx'
import { now } from 'mobx-utils'

import { getInitials, parseDate } from '@src/lib'
import { fullName } from '@src/service/model'
import type {
  Identity,
  IdentityPhone,
  MemberModel,
  Model,
  PortRequestStatus,
} from '@src/service/model'
import AvailabilityHours from '@src/service/model/AvailabilityHours'
import type PhoneNumberStore from '@src/service/phone-number-store'
import type {
  AvailabilityHours as IAvailabilityHours,
  PhoneNumberSettings,
  UserPhoneNumber,
  RoleName,
} from '@src/service/transport/account'

import PhoneNumberGroup from './PhoneNumberGroup'

export interface CodablePhoneNumber {
  availabilityHours: IAvailabilityHours | null
  createdAt: number | null
  groupId: string | null
  id: string
  mutedUntil: number | null
  name: string
  number: string
  symbol: string
  role: RoleName | null
  formattedNumber: string | null
  settings: PhoneNumberSettings | null
  updatedAt: number | null
  users: UserPhoneNumber[]
  portingStatus: PortRequestStatus | null
  portRequestId: string | null
}

export default class PhoneNumberModel
  implements Identity, Model, CodablePhoneNumber, IdentityPhone
{
  private raw: CodablePhoneNumber

  constructor(
    private phoneNumberStore: PhoneNumberStore,
    attrs: CodablePhoneNumber,
  ) {
    this.raw = attrs

    makeAutoObservable(this, {
      isSharedWith: false,
    })
  }

  get id(): string {
    return this.raw.id
  }

  set id(value: string) {
    this.raw.id = value
  }

  get createdAt(): number | null {
    return this.raw.createdAt
  }

  set createdAt(value: number | null) {
    this.raw.createdAt = value
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt
  }

  set updatedAt(value: number | null) {
    this.raw.updatedAt = value
  }

  get mutedUntil(): number | null {
    return this.raw.mutedUntil
  }

  set mutedUntil(value: number | null) {
    this.raw.mutedUntil = value
  }

  get name(): string {
    return this.raw.name
  }

  set name(value: string) {
    this.raw.name = value
  }

  get number(): string {
    return this.raw.number
  }

  set number(value: string) {
    this.raw.number = value
  }

  get symbol(): string {
    return this.raw.symbol
  }

  set symbol(value: string) {
    this.raw.symbol = value
  }

  get settings(): PhoneNumberSettings | null {
    return this.raw.settings
  }

  set settings(value: PhoneNumberSettings | null) {
    this.raw.settings = value
  }

  get portRequestId(): string | null {
    return this.raw.portRequestId
  }

  set portRequestId(value: string | null) {
    this.raw.portRequestId = value
  }

  get groupId() {
    return this.raw.groupId
  }

  set groupId(value: string | null) {
    this.raw.groupId = value
  }

  get users(): UserPhoneNumber[] {
    return this.raw.users
  }

  set users(value: UserPhoneNumber[]) {
    this.raw.users = value
  }

  get portingStatus(): PortRequestStatus | null {
    return this.raw.portingStatus
  }

  set portingStatus(value: PortRequestStatus | null) {
    this.raw.portingStatus = value
  }

  get formattedNumber(): string | null {
    return this.raw.formattedNumber
  }

  set formattedNumber(value: string | null) {
    this.raw.formattedNumber = value
  }

  get role(): RoleName | null {
    return this.raw.role
  }

  set role(value: RoleName | null) {
    this.raw.role = value
  }

  get formattedName(): string {
    return `${this.symbol || '📱'} ${this.name}`
  }

  get shortName(): string {
    return this.name
  }

  get initials() {
    return getInitials(this.name)
  }

  get phones(): IdentityPhone[] {
    return []
  }

  get group() {
    return this.groupId ? new PhoneNumberGroup(this.groupId, this) : null
  }

  get pictureSymbol() {
    return this.symbol
  }

  get emailAddresses(): string[] {
    return []
  }

  get isAnonymous(): boolean {
    return false
  }

  get muted() {
    if (!this.mutedUntil || this.mutedUntil <= Date.now()) {
      return false
    }
    return this.mutedUntil > now(1000 * 60)
  }

  get isShared(): boolean {
    return this.users.length > 1
  }

  get availabilityHours(): AvailabilityHours | null {
    return this.raw.availabilityHours
      ? new AvailabilityHours().deserialize(this.raw.availabilityHours)
      : null
  }

  set availabilityHours(value: AvailabilityHours | null) {
    this.raw.availabilityHours = value?.serialize() ?? null
  }

  get isOffHours(): boolean {
    return this.availabilityHours?.isOffHours ?? false
  }

  get members(): MemberModel[] {
    return this.phoneNumberStore.getMembers(this)
  }

  get userNames(): string | null {
    if (!this.users) {
      return null
    } else if (this.users.length === 1) {
      // @ts-expect-error unchecked index access
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  get isPorting() {
    return !!this.portingStatus && this.portingStatus !== 'completed'
  }

  isSharedWith(memberId: string): boolean {
    return this.users.some((user) => user.id === memberId)
  }

  update = (attributes: Partial<CodablePhoneNumber>) => {
    Object.assign(this, attributes)

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.phoneNumberStore.update(this.serialize())
  }

  mute = (until: Date) => {
    this.mutedUntil = until.getTime()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.phoneNumberStore.mute(this.serialize())
  }

  unmute = () => {
    this.mutedUntil = null
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.phoneNumberStore.unmute(this.serialize())
  }

  deserialize = ({
    createdAt,
    mutedUntil,
    updatedAt,
    ...attrs
  }: Partial<CodablePhoneNumber>) => {
    this.createdAt = createdAt ? parseDate(createdAt) : null
    this.mutedUntil = mutedUntil ? parseDate(mutedUntil) : null
    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    if (attrs) {
      this.raw = { ...this.raw, ...attrs }
    }
    return this
  }

  serialize = (): CodablePhoneNumber => {
    return toJS(this.raw)
  }
}
