/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import { memo } from 'react'

export type SpacerSize =
  | 4
  | 8
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  // eslint-disable-next-line @typescript-eslint/ban-types -- Empty object is used to brand the type and avoid literal type widening
  | (number & {})

interface SquareSpacerProps {
  size: SpacerSize
  width?: never
  height?: never
  flex?: never
}

type FreeFormSpacerProps = (
  | {
      width: SpacerSize
    }
  | {
      height: SpacerSize
    }
  | {
      width: SpacerSize
      height: SpacerSize
    }
) & {
  flex?: never
  size?: never
}

interface Flex1SpacerProps {
  flex: true
  size?: never
  width?: never
  height?: never
}

export type SpacerProps = (SquareSpacerProps | FreeFormSpacerProps | Flex1SpacerProps) & {
  inline?: boolean
}

export const Spacer = (props: SpacerProps) => {
  const Component = props.inline ? 'span' : 'div'

  return <Component style={_getSpacerStyle(props)} />
}

export default memo(Spacer)

/**
 * @internal This is exported for testing purposes only
 */
export function _getSpacerStyle(props: SpacerProps): React.CSSProperties {
  if ('size' in props) {
    return {
      width: props.size,
      height: props.size,
    }
  }

  if ('flex' in props) {
    return {
      flex: 1,
    }
  }

  const style: React.CSSProperties = {}

  if ('width' in props) {
    style.width = props.width
  }

  if ('height' in props) {
    style.height = props.height
  }

  return style
}
