import { observer } from 'mobx-react-lite'

import AnimatingLogo from '@src/app/components/AnimatingLogo'
import RotatingTips from '@src/app/components/RotatingTips'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import { LoadingIndicator } from '@ui/LoadingIndicator'
import ProgressBar from '@ui/ProgressBar'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './ProgressContent.css'

const ProgressContent = () => {
  const {
    signOut,
    reset: resetState,
    loadingPercentage,
    initializationStatus,
  } = useAppStore()
  const percentage = Math.min(loadingPercentage, 100)

  const handleReload = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    resetState()
  }

  const handleSignout = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    signOut()
  }

  const loadingProgress = (
    <>
      <AnimatingLogo />
      <ProgressBar
        className={styles.progressBar}
        value={percentage}
        label="Loading application..."
        status={initializationStatus === 'failed' ? 'error' : 'active'}
        aria-hidden
      />
    </>
  )

  if (initializationStatus === 'failed') {
    return (
      <>
        {loadingProgress}
        <Typography
          variant="callout"
          color="textSecondary"
          className={styles.errorMessage}
        >
          Unfortunately this is taking too long.
          <br />
          Please choose one of the options below to reset your session.
        </Typography>
        <div className={styles.btnWrapper}>
          <Button
            height={36}
            variant="outlined"
            color="textPrimary"
            onClick={handleReload}
          >
            <Typography variant="footnote" fontWeight="medium">
              Reload account
            </Typography>
          </Button>
          <Button
            height={36}
            variant="outlined"
            color="textPrimary"
            onClick={handleSignout}
          >
            <Typography variant="footnote" fontWeight="medium">
              Log out
            </Typography>
          </Button>
        </div>
      </>
    )
  }

  if (initializationStatus === 'updating') {
    return (
      <>
        <LoadingIndicator variant="circular" color="primary" size={40} />
        <Typography variant="title3" className={styles.updatingTitle}>
          Updating the app
        </Typography>
        <RotatingTips aria-hidden />
      </>
    )
  }

  return (
    <>
      {loadingProgress}
      <RotatingTips aria-hidden />
      <VisuallyHidden>
        {percentage >= 100 ? 'Loaded' : `Loading application...`}
      </VisuallyHidden>
    </>
  )
}

export default observer(ProgressContent)
