import { observer } from 'mobx-react-lite'
import { useId } from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import { Command, CommandFooter, CommandHeader } from '@ui/Command'
import Modal from '@ui/Modal'
import { theme } from '@ui/theme'

import * as styles from './UniversalLoginReauthenticateInfoModal.css'

const UniversalLoginReauthenticateInfoModal = () => {
  const { service } = useAppStore()
  const titleId = useId()

  if (!service.authV2.displayLogoutReason) {
    return null
  }

  const handleModalClose = () => {
    service.authV2.hideLogoutReason()
  }

  const handlePrimaryAction = () => {
    service.authV2.hideLogoutReason()
  }

  const handleSecondaryAction = () => {
    service.authV2.hideLogoutReason()
    // TODO: UXP-3753 add navigation link
  }

  return (
    <Modal
      underlayColor={`rgba(${theme.palette.overlay.fullscreen})`}
      underlayClassName={styles.modal}
      className={styles.dialog}
      onClose={handleModalClose}
      isDismissable={true}
    >
      <Command width={464} aria-labelledby={titleId}>
        <CommandHeader
          title="You've been logged out"
          titleId={titleId}
          subtitle="For your security you’ve been automatically logged out. Please log back in to continue using OpenPhone."
        />
        <CommandFooter
          hideDivider={true}
          rightContent={
            <div className={styles.actionButtonsContainer}>
              <Button
                color="textSecondary"
                variant="text"
                onClick={handleSecondaryAction}
              >
                Learn more
              </Button>
              <Button onClick={handlePrimaryAction}>Got it</Button>
            </div>
          }
        />
      </Command>
    </Modal>
  )
}

export default observer(UniversalLoginReauthenticateInfoModal)
